@use "../colors";

.event-card {
  text-decoration: none;
  color: inherit;
  @media screen and (max-width: 460px) {
    text-decoration: none;
    color: #ffffff;
    display: block;
    width: 100%;
  }
  &:hover {
    color: inherit;
  }
  &--completed {
    .event-card--big .event-card__content,
    .event-card__content {
      background: no-repeat url("../../components/Res/pastMark.svg"), rgba(0, 0, 0, 0.90);
      z-index: 11;

      @media (max-width: 460px) {
        background-size: contain;
        background-position: center 10px;
      }

    }
    .event-card__author {
      z-index: 12;
    }
    .event-card__in-deal {
      display: none;
    }
    .event-card__member {
      display: none;
    }
  }
  &__wrap {
    width: 440px;
    height: 243px;
    position: relative;
    display: flex;
    background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 10px;

    @media screen and (max-width: 460px) {
      flex-direction: column;
      width: 100%;
      height: unset;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: calc(100% - 26px);
      position: absolute;
      z-index: 3;
      top: 13px;
      background: linear-gradient(to bottom, #ffffff, #5a5959);
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
  &__bg {
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      z-index: 2;
      display: block;
      height: 50%;
      width: 100%;
      pointer-events: none;
    }
    &:before {
      top: 0;
      border-radius: 10px 10px 0 0;
      border: 1px solid #ffffff;
      border-bottom: none;
    }
    &:after {
      bottom: 0;
      border-radius: 0 0 10px 10px;
      border: 1px solid #5a5959;
      border-top: none;
    }
  }

  &--big {
    padding: 0;

    .event-card__wrap {
      width: 459px;
      height: 506px;
      flex-direction: column;

      @media screen and (max-width: 460px) {
        height: 496px;
        width: calc(100vw - 30px);
      }
    }

    .event-card__fav {
      top: 247px;
      right: 20px;
      z-index: 12;

      @media screen and (max-width: 460px) {
        top: 247px;
        right: 10px;
      }
    }

    .event-card__img {
      //position: relative;
      width: 100%;
      height: 229px;

      //@media screen and (max-width: 460px) {
      //  height: 164px;
      //}
    }

    .event-card__author {
      top: 160px;
      //right: 20px;
      bottom: unset;
      //right: unset;

      //&-tooltip {
      //  left: calc(100% + 5px);
      //  bottom: unset;
      //  right: unset;
      //  transform: translateY(-50%);
      //  top: 50%;
      //
      //  &:before {
      //    border: 4px solid #ffffff;
      //    border-left: none;
      //    border-top-color: transparent;
      //    border-bottom-color: transparent;
      //    left: -5px;
      //    right: unset;
      //    bottom: unset;
      //    top: calc(50% - 4px);
      //  }
      //}
    }

    .event-card__content {
      margin-left: 0;
      padding: 249px 20px 20px;
      background-color: transparent;

      @media screen and (max-width: 460px) {
        padding: 249px 10px 10px;
      }
    }

    .event-card__type {
      line-height: 17px;
    }

    .event-card__title {
      margin-top: 4px;
      margin-bottom: 10px;
      font-size: 26px;
      line-height: 32px;
    }

    .event-card__repeat {
      margin-top: 19px;
    }

    .event-card__more {
      @media screen and (max-width: 460px) {
        text-decoration: underline;
      }
    }
    .event-card__footer {
      margin-top: auto;
      padding-right: 0;
    }
  }

  &__fav {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 12;
    cursor: pointer;

    @media screen and (max-width: 460px) {
      right: 16px;
    }

    svg {
      display: block;
      stroke: colors.$primary;
      fill: transparent;
    }
    &--active {
      svg {
        fill: colors.$primary;
      }
    }
  }

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;

    @media screen and (max-width: 460px) {
      width: 100%;
      margin-right: 0;
      border-radius: 10px;
    }
  }
  &__author {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 4;
    display: block;
    width: 49px;
    height: 49px;
    border-radius: 50%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      border: 1px solid #FFFFFF;
    }
    &-tooltip {
      position: absolute;
      bottom: calc(100% + 5px);
      right: 0;
      z-index: 10;
      text-align: center;
      padding: 15px;
      border: 1px solid #ffffff;
      text-transform: none;
      pointer-events: none;
      color: #FFFFFF;
      opacity: 0;
      background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
      box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(20px);
      border-radius: 10px;
      transition: 0.2s ease-in;

      &:before {
        content: '';
        display: block;
        border: 4px solid #ffffff;
        border-bottom: none;
        border-left-color: transparent;
        border-right-color: transparent;
        position: absolute;
        right: 20px;
        bottom: -5px;
      }
    }
    &:hover {
      .event-card__author-tooltip {
        opacity: 1;
      }
    }
    &--empty {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 5px;
      background-color: #fff;
      img {
        border-radius: 0;
        opacity: 0.2;
        scale: 0.7;
      }
    }
  }
  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.90);
    border-radius: 10px;
  }
  &__type {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #FFFFFF;
    opacity: 0.8;
  }
  &__title {
    margin-bottom: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

    @media screen and (max-width: 460px) {
      margin-bottom: 5px;
      font-size: 20px;
      line-height: 24px;
    }
  }
  &__details {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    opacity: 0.8;

    @media screen and (max-width: 460px) {
      font-size: 12px;
      line-height: 15px;
      opacity: 1;
    }

    &:not(:last-of-type) {
      margin-bottom: 5px;
    }

    svg {
      display: block;
      margin-right: 8px;
      flex-shrink: 0;
    }
  }

  &__repeat {
    margin-top: auto;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: #ffffff;
    opacity: 0.8;

    span {
      color: colors.$primary;
    }
  }

  &__footer {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 60px;
  }

  &__more {
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: underline;
    color: colors.$primary;
    transition: 0.3s color;

    &:hover {
      color: #8b5335;
    }
  }

  &__in-deal {
    width: 98px;
    height: 40px;
    padding: 0 10px;
    line-height: 20px;
    text-transform: none;
    background-color: inherit;
    border-color: colors.$primary;
  }

  &__member {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 10px;
    line-height: 20px;
    background-color: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
  }

  &.big {
    grid-row: span 2;
    flex-direction: column;
    padding: 18px 19px 20px;

    > img {
      width: 100%;
      height: 229px;
      margin-bottom: 20px;
    }

    .eventType {
      margin-bottom: 5px;
      line-height: 17px;
    }

    .eventTitle {
      margin-bottom: 10px;
      font-size: 26px;
      line-height: 32px;
    }

    .details {
      font-size: 14px;
      line-height: 17px;

      &:nth-child(5) {
        margin-bottom: 19px;
      }
    }

    .repeat {
      margin-bottom: 15px;
      font-size: 14px;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .marker {
      top: 267px;
      right: 20px;
    }
  }
}

.event-card__wrap-same {
  display: flex;
  flex-direction: column;
  width: 335px;
  height: 445px;
  img{
    height: 215px;
    position: relative;
    border-radius: 0;
    border: none;
    flex-shrink: 0;
  }
  .event-card__content{
    background: #373737;
    border-radius: 0;
    border: none;
    height: 80%;
  }
  .event-card__bg:before, .event-card__bg:after {
    top: 0;
    border-radius: 10px 10px 0 0;
    border: none;
    border-bottom: none;
  }
  .event-card__details {
    svg {
      fill: colors.$primary;
    }
  }
  .event-card__footer {
    margin-top: auto;
    justify-content: center;
    padding: 0;
  }
  &:before,
  &:after {
    content: '';
    display: none;
    width: 1px;
    height: calc(100% - 26px);
    position: absolute;
    z-index: 3;
    top: 13px;
    background: none;
  }

  @media screen and (max-width: 460px) {
    width: 100%;
  }
}
