.sos-item {
  &--breakdown {
    .sos-item__wrap {
      background: linear-gradient(131.65deg, rgba(242, 42, 42, 0.3) 7.4%, rgba(242, 42, 42, 0.03) 92.98%);
    }
  }
  &--medic {
    .sos-item__wrap {
      background: linear-gradient(145.9deg, rgba(11, 100, 242, 0.3) 17.78%, rgba(11, 100, 242, 0.03) 76.8%);
    }
  }
  &--lawyer {
    .sos-item__wrap {
      background: linear-gradient(145.9deg, rgba(255, 89, 0, 0.3) 17.78%, rgba(255, 89, 0, 0.03) 76.8%);
    }
  }
  &--closed {
    .sos-item__wrap {
      background: linear-gradient(145.9deg, rgba(62, 62, 62, 0.3) 17.78%, rgba(255, 255, 255, 0.03) 76.8%)
    }
    .sos-item__content {
      opacity: 0.3;
    }
  }
  &__wrap {
    width: 440px;
    height: 221px;
    position: relative;
    display: flex;

    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 10px;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: calc(100% - 26px);
      position: absolute;
      z-index: 3;
      top: 13px;
      background: linear-gradient(to bottom, #ffffff, #5a5959);
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
  &__bg {
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      z-index: 2;
      display: block;
      height: 50%;
      width: 100%;
      pointer-events: none;
    }
    &:before {
      top: 0;
      border-radius: 10px 10px 0 0;
      border: 1px solid #ffffff;
      border-bottom: none;
    }
    &:after {
      bottom: 0;
      border-radius: 0 0 10px 10px;
      border: 1px solid #5a5959;
      border-top: none;
    }
  }
  &__content {
    width: 100%;
    height: 100%;
    padding: 25px 20px 20px;
    display: flex;
    flex-direction: column;
  }
  &__head {
    display: flex;
    align-items: center;
  }
  &__avatar {
    flex-shrink: 0;
    width: 113px;
    height: 113px;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  &__user {
    margin-left: 23px;
  }
  &__name {
    padding-top: 15px;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    color: #FFFFFF;
  }
  &__phone {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    opacity: 0.7;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;

    svg {
      flex-shrink: 0;
      display: block;
      margin-right: 5px;
    }
  }
  &__address {
    display: flex;
    align-items: center;
    width: 160px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
  }
  &__info {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    color: #FFFFFF;
  }
}
