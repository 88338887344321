@use "../colors";

.select {
  position: relative;

  &.city-selector {
    .select__wrap {
      height: 44px;

      svg {
        top: 8px;
      }
    }
  }

  &--primary {
    color: colors.$primary;
  }

  //&--travel {
  //  color: white;
  //  .select__wrap {
  //    position: relative;
  //    height: 54px;
  //    border: 1px solid #ff5900;
  //    border-radius: 4px;
  //    cursor: pointer;
  //    border-radius: 50px;
  //}
  //  input:focus-visible {
  //    outline: none;
  //  }
  //}

  &__wrap {
    position: relative;
    height: 54px;
    border: 1px solid #ffffff;
    border-radius: 4px;
    cursor: pointer;

    svg {
      position: absolute;
      top: 15px;
      right: 10px;
      opacity: 0.5;
    }
  }
  &__search {
    height: 100%;
    input {
      display: block;
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;
      color: #FFFFFF;
      padding: 0 10px;
      &:focus {
        outline: none;
      }
    }
  }
  &__placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #c3c3c3;
    opacity: 0.8;
    width: 100%;
  }
  &__current {
    display: flex;
    align-items: center;
    padding: 0 34px 0 10px;
    height: 100%;
    width: 100%;
    pointer-events: none;
    > div {
      &:not(.select__placeholder) {
        width: 100%;
      }
    }
  }
  &__options {
    max-height: 374px;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;
    width: 100%;
    padding: 7px 0;
    border: 1px solid #ffffff;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: #201e1e;
    overflow-y: scroll;
  }
  &__item {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
    &:hover {
      background-color: #2b2828;
    }
    &--empty {
      cursor: default;
      color: rgba(#ffffff, 0.5);
      &:hover {
        background-color: #201e1e;
      }
    }
  }
  &--opened {
    .select__wrap {
      border-radius: 4px 4px 0 0;
      border-color: colors.$primary;
    }
    .select__wrap svg {
      transform: rotateZ(180deg);
    }
    .select__current {
      pointer-events: unset;
    }
    .select__options {
      display: block;
      border-color: colors.$primary;
    }
  }
}
