@use "../colors";

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 0 16px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  border-radius: 4px;
  border: 1px solid #ffffff;
  background-color: colors.$primary;
  transition: 0.3s background-color;

  &:hover {
    background-color: #ff4100;
  }

  @media (max-width: 460px) {
    font-size: 14px;
    text-transform: unset;
  }

  &--ghost {
    border: 1px solid colors.$primary;
    background-color: transparent;
  }
}
