.main-slider {
  max-width: 1920px;
  margin: 0 auto;
  height: 667px;
  position: relative;

  @media screen and (max-width: 1000px) {
    height: 540px;
  }
  @media screen and (max-width: 460px) {
    height: 204px;
  }

  .swiper {
    height: 100%;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 5;
      width: 50%;
      background: rgba(215, 215, 215, 0.1);
      box-shadow: inset 0 4px 16px rgba(233, 233, 233, 0.2), inset 0 1px 40px rgba(226, 226, 226, 0.2),
        inset 0px -80px 65px rgba(212, 212, 212, 0.2);
      backdrop-filter: blur(10px);
      pointer-events: none;

      @media screen and (max-width: 1000px) {
        width: 100%;
        box-shadow: none;
        backdrop-filter: none;
      }
    }
  }

  .swiper-pagination {
    position: absolute;
    bottom: 15px;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);
    display: flex;

    @media screen and (max-width: 460px) {
      display: none;
    }
  }

  &__bullet {
    display: block;
    width: 10px;
    height: 10px;
    border: 1px solid #ffffff;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 15px;
    }

    &--active {
      background-color: #fff;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
