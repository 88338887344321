.profile-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  @media (max-width: 460px) {
    margin-top: 30px;
  }
  &__item {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    cursor: pointer;

    @media (max-width: 460px) {
      font-size: 14px;
    }

    &:not(:last-child) {
      margin-right: 40px;

      @media (max-width: 460px) {
        margin-right: 15px;
      }
    }

    &.active {
      padding: 11px 37px;
      background: #201e1e;
      box-shadow: 0 6px 7px rgba(255, 255, 255, 0.05);
      border-radius: 10px;
      border: 1px solid #ffffff;

      @media (max-width: 460px) {
        padding: 11px 18px;
      }
    }
  }
}
