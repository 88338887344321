.profile-events-list {
  margin-top: 20px;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;

  .event-card {
    margin-right: 30px;
    margin-bottom: 30px;
    text-decoration: none;
    color: inherit;
    @media (max-width: 460px) {
      margin-right: 0;
      margin-bottom: 20px;
      width: 100%;
    }
  }
  //> div {
  //  width: 440px;
  //  margin-right: 30px;
  //  margin-bottom: 30px;
  //
  //  &:nth-child(3n) {
  //    margin-right: 30px;
  //  }
  //  &:nth-child(2n) {
  //    margin-right: 0!important;
  //  }
  //}

  &--old {
    .event-card__content{
      background: no-repeat url("../../../components/Res/pastMark.svg"), rgba(0, 0, 0, 0.7);
      z-index: 11;

      @media (max-width: 460px) {
        background-size: contain;
        background-position: center center;
      }

    }
    .event-card__author {
      z-index: 12;
    }
  }
}
