@use "../../colors";

.travel-page-comments {
  margin: 80px auto 0;

  @media (max-width: 460px) {
    width: 100%;
    margin-top: 80px;
  }

  &__head {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #888888;

    @media (max-width: 460px) {
      padding: 0 0 10px;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 41px;
    line-height: 50px;
    color: #FFFFFF;
    flex-grow: 1;

    @media (max-width: 460px) {
      font-size: 26px;
      line-height: 32px;
    }
  }
  &__list {
    @media (max-width: 460px) {

    }
  }
  &__footer {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
  &__new {
    margin-top: 30px;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    p {
      width: 100%;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 3px;
    }

    textarea {
      background-color: transparent;
      border: 1px solid #ffffff;
      color: #ffffff;
      outline: none;
      width: 360px;
      height: 120px;
      font-family: inherit;
      padding: 5px;
      border-radius: 4px;
      resize: none;

      @media (max-width: 600px) {
        width: 100%;
      }

      &:focus {
        outline: none;
        border-color: colors.$primary;
      }
    }
  }
  &__submit {
    margin-left: 20px;

    @media (max-width: 600px) {
      margin-left: 0;
      margin-top: 20px;
      width: 100%;
    }
  }
}

.travel-page-comments-item {
  margin-top: 20px;
  &:last-child {
    @media (max-width: 460px) {

    }
  }
  &__wrap {
    display: flex;
    @media (max-width: 460px) {

    }
  }
  &__avatar {
    flex-shrink: 0;
    a {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #fff;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &--empty {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 9px 5px 5px;

        img {
          object-fit: contain;
          height: unset;
          opacity: 0.2;
        }
      }
    }
  }
  &__content {
    margin-left: 10px;
    flex-grow: 1;
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 460px) {
      display: block;
    }
  }
  &__name {
    a {
      font-size: 18px;
      line-height: 20px;
      font-weight: 500;
      color: #ffffff;
      text-decoration: none;
      @media (max-width: 460px) {
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
  &__date {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;

    color: rgba(#fff, 0.35);

    @media (max-width: 460px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
  &__text {
    margin-top: 5px;
    font-weight: 400;
    font-size: 15px;
    line-height: 160%;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 5px;
    background-color: rgba(#fff, 0.05);
  }
}
