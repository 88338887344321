@use "../colors";

.date-picker {
  position: relative;
  &--active {
    .date-picker__selected {
      border-color: colors.$primary;
      border-radius: 4px 4px 0 0;
    }
  }
  &__selected {
    display: flex;
    align-items: center;
    border: 1px solid #ffffff;
    width: 100%;
    height: 44px;
    padding: 0 10px;
    border-radius: 4px;
  }
  &__value {
    flex-grow: 1;
    &--placeholder {
      color: #ffffff;
      opacity: 0.5;
    }
  }
  &__icon {
    flex-shrink: 0;
    color: colors.$primary;
    svg {
      display: flex;
    }
    &--arrow {
      padding: 0 10px;
    }
    &--calendar {
      padding: 0 0 0 10px;
    }
  }
  &__wrap {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10;
    .react-calendar {
      width: unset;
      border: 1px solid colors.$primary;
      border-top: none;
      border-radius: 0 0 4px 4px;
      background-color: #201E1E;
      &__viewContainer {
        position: relative;
        z-index: 2;
      }
      &--selectRange {
        .react-calendar__tile {
          &:enabled:hover,
          &:enabled:focus {
            background-color: transparent;
            border: 1px solid colors.$primary;
          }
          &--range {
            background-color: colors.$primary;
          }
          &--rangeStart {
            background-color: colors.$primary;
          }
          &--rangeEnd {
            background-color: colors.$primary;
          }
          &--hover {
            background: colors.$primary;
            border: 1px solid colors.$primary;
          }
        }
      }
      &__tile {
        border: 1px solid transparent;
        &:enabled:hover,
        &:enabled:focus {
          background-color: transparent;
          border: 1px solid colors.$primary;
        }
        &:disabled {
          background-color: transparent;
          opacity: 0.1;
        }
        &--now {
          background-color: rgba(colors.$primary, 0.5);
          border: 1px solid colors.$primary;
        }
        &--active,
        &--hasActive {
          background-color: colors.$primary;
        }
      }
      &__month-view__days__day {
        color: #ffffff;
        &--neighboringMonth {
          color: rgba(#ffffff, 0.3);
        }
      }
      &__year-view__months__month,
      &__decade-view__years__year {
        color: #ffffff;
      }
      &__navigation {
        &__arrow {
          &:enabled:hover,
          &:enabled:focus {
            background-color: transparent;
          }
          &:disabled {
            background-color: transparent;
            opacity: 0.1;
          }
        }
        &__label {
          &:enabled:hover,
          &:enabled:focus,
          &:disabled:hover,
          &:disabled:focus {
            background-color: #201E1E;
          }
        }
        button {
          min-width: 44px;
          background: none;
          color: #fff;
          font-size: 16px;
        }
      }
    }
  }
}
