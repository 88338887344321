.profile-hero {
  position: relative;
  height: 450px;

  @media (max-width: 1240px) {
    height: 330px;
  }

  @media (max-width: 460px) {
    height: 270px;
    display: flex;
    justify-content: center;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  &__hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    transition: 0.2s linear;

    @media (max-width: 460px) {
      display: none;
    }

    button {
      width: 290px;
    }
  }
  &__size {
    margin-top: 10px;
    font-size: 14px;
  }
  &__hover-mobile {
    display: none;
    position: absolute;
    bottom: 87px;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;

    @media (max-width: 460px) {
      display: flex;
    }
  }
  &:hover {
    .profile-hero__hover {
      pointer-events: auto;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}
