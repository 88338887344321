.profile-route-events {
  &__head {
    display: flex;
    align-items: center;

    @media (max-width: 460px) {
      flex-wrap: wrap;
    }

    .profile-events-ownership__tabs {
      @media (max-width: 460px) {
        order: 1;
        justify-content: center;
      }
    }
  }
  &__title {
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
  }
  &__add {
    margin-left: auto;
    @media (max-width: 460px) {
      margin: 20px 0;
      width: 100%;
      order: 0;
    }
    .btn {
      width: 200px;
      @media (max-width: 460px) {
        width: 100%;
      }
    }
  }
  &__list {
    margin-top: 20px;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;

    .route-event-card {
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }
  &__empty {
    margin: 30px 0;
  }
  &__loading {
    margin-bottom: 30px;
  }
}
