.profile-create-event-users {
  margin-top: 25px;
  &__avatar {
    width: 52px;
    height: 52px;
    border-radius: 50%;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    &--add {
      width: 66px;
      .profile-create-event-users__avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
      }
      .profile-create-event-users__desc {
        margin-top: 11px;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
      }
    }
  }
}
