.profile-page {
  &__wrap {
    display: flex;

    @media (max-width: 1240px) {
      flex-direction: column;
    }
  }
  &__aside {
    flex-shrink: 0;
    position: relative;
    width: 373px;

    @media (max-width: 1240px) {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-top: -220px;
    }
    @media (max-width: 460px) {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: -72px;
    }
  }
  &__main {
    flex-grow: 1;
    padding: 40px 0 0 40px;
    min-height: 600px;
    //width: 950px;

    @media (max-width: 1240px) {
      padding: 40px 0 0;
    }

    @media (max-width: 460px) {
      padding: 0;
      min-height: unset;
    }
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px 10px;
    overflow-x: auto;
  }
  &__filter {
    flex-shrink: 0;
    display: flex;
    align-items: center;

    @media (max-width: 460px) {
      display: none;
    }
    svg {
      display: block;
      margin-right: 5px;
    }
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #C3C3C3;
    }
  }
  &__tabs {
  }
  &__content {
    //@media (max-width: 460px) {
    //  padding: 0 15px;
    //}
  }
  &__subtitle {
    margin-top: 25px;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;

    @media (max-width: 460px) {
      margin-top: 0;
      font-size: 20px;
      line-height: 24px;
    }
  }
}
