.profile-events-ownership {
  display: flex;
  align-items: center;
  margin-top: 40px;

  @media (max-width: 760px) {
    flex-wrap: wrap;
  }
  @media (max-width: 460px) {
    margin-top: 35px;
  }
  &__tabs {
    display: flex;
    align-items: center;

    @media (max-width: 760px) {
      width: 100%;
      margin-bottom: 14px;
    }
  }
  &__item {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 15px;
    }
    &--active {
      font-weight: 500;
      border-color: #ffffff;
      cursor: default;
    }
  }
  &__filter {
    margin-left: auto;
    display: flex;
    align-items: center;

    @media (max-width: 760px) {
      margin-left: 0;
      width: 100%;
    }
    .main-events__search {
      margin-left: 0;
      @media (max-width: 760px) {
        flex-grow: 1;
      }
    }
    .main-events__filter {
      @media (max-width: 760px) {
        flex-shrink: 0;
      }
    }
  }
}
