.travel-page-map {
  width: 100%;
  height: 100%;
  .ymaps-layers-pane {
    filter: grayscale(1);
  }
  .ymaps-2-1-79-areas-pane,
  .ymaps-2-1-79-ground-pane {
    filter: invert(100%) grayscale(1);
  }
  .ymaps-2-1-79-routerPoints-pane {
    display: none;
  }
}
