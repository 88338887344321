.about-page-hero {
  position: relative;
  width: 100%;
  min-height: 700px;
  max-height: 900px;
  height: 100vh;

  @media (max-width: 460px) {
    max-height: unset;
    min-height: unset;
    height: 568px;
  }

  &__wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5));

    @media (max-width: 1000px) {
      padding: 0 30px;
    }

    @media (max-width: 700px) {
      padding: 0 20px;
    }

    @media (max-width: 460px) {
      padding: 0 10px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    }
  }

  &__logo {
    svg {
      @media (max-width: 460px) {
        width: 61px;
      }
    }
  }

  &__title {
    margin: 30px 0 0;
    font-weight: 500;
    font-size: 40px;
    line-height: 49px;
    text-transform: uppercase;
    text-align: center;
    color: #FFFFFF;
    padding-bottom: 15px;
    border-bottom: 1px solid #FFFFFF;

    @media (max-width: 460px) {
      margin-top: 10px;
      padding-bottom: 10px;
      font-size: 17.866px;
      line-height: 22px;
    }
  }

  &__desc {
    width: 290px;
    margin: 32px 0 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;

    @media (max-width: 460px) {
      margin-top: 20px;
      font-size: 14px;
      line-height: 17px;
    }
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
