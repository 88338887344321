.toProfile {
  @media screen and (max-width: 460px) {
    display: none;
  }
}

.burger {
  //display: none;
  //
  //@media screen and (max-width: 1000px) {
  //  display: block;
  //}
}
