@use "../colors";

.gallery {
  width: 100%;
  &__head {
    width: 1400px;
    margin: 0 auto;
    padding-bottom: 20px;
    border-bottom: 1px solid #888888;
    display: flex;
    align-items: flex-end;

    @media (max-width: 1440px) {
      width: calc(100% - 100px);
    }

    @media (max-width: 1000px) {
      width: calc(100% - 60px);
    }

    @media (max-width: 700px) {
      width: calc(100% - 40px);
    }

    @media screen and (max-width: 460px) {
      width: calc(100% - 20px);
    }
  }
  &__title {
    font-weight: 500;
    font-size: 41px;
    line-height: 50px;
    color: #FFFFFF;
    margin: 0;

    @media screen and (max-width: 460px) {
      font-size: 22px;
      line-height: 28px;
      white-space: normal;
    }
  }
  &__nav {
    margin-left: auto;
    padding-bottom: 10px;

    @media (max-width: 460px) {
      padding-bottom: 0;
    }
  }
  &__wrap {
    width: 100%;
    overflow: hidden;
  }
  &__item {
    display: block;
    width: auto;
    height: 330px;
    cursor: pointer;
  }
  &__slider {
    width: 1400px;
    margin: 30px auto 0;
    @media (max-width: 1400px) {
      width: 100%;
    }
    .swiper {
      overflow: visible;
      margin: 0;
    }
    .swiper-slide {
      width: fit-content;
    }
  }
}
.gallery-nav {
  display: flex;
  align-items: center;

  &__item {
    cursor: pointer;
    &:hover {
      svg {
        fill: colors.$primary;
      }
    }

    svg {
      fill: #ffffff;
      transition: 0.2s linear;
    }
    &--next {
      margin-left: 25px;
    }
    &.swiper-button-disabled {
      cursor: default;
      opacity: 0.3;

      &:hover {
        svg {
          fill: #ffffff;
        }
      }
    }
  }
}
.gallery-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(#1E1E1E, 0.8);
  display: none;
  justify-content: center;
  align-items: center;
  &__wrap {
    position: relative;
    //width: 1245px;
    width: 849px;
  }
  &__close {
    position: absolute;
    top: -40px;
    right: -64px;
    cursor: pointer;
  }
  &__content {
    display: flex;
  }
  &__view {
    width: 849px;
    height: 566px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #201E1E;

    .swiper {
      height: 100%;
    }
    .swiper-wrapper {
      height: 100%;
    }
    .swiper-button-prev,
    .swiper-button-next {
      color: #ffffff;
      transition: 0.2s linear;
      &:hover {
        color: colors.$primary;
      }
    }
  }
  &__item {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &--active {
    display: flex;
  }
}
