.profile-files {
  margin-top: 40px;
  @media (max-width: 460px) {
    margin-top: 0;
  }
  &__head {
    display: flex;
    align-items: center;

    @media (max-width: 460px) {
      align-items: center;
      margin-top: 12px;
    }

    .profile-page__subtitle {
      margin: 0;
    }
  }
  &__item {
    margin-bottom: 10px;
  }
  &__title {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
  }
  &__empty {
    margin: 30px 0;
  }
  &__loading {
    margin-bottom: 30px;
  }
}
