.profile-create-event-types {
  margin: 16px 0 0;

  &__item {
    &:not(:first-child) {
      margin-top: 25px;
    }
  }
  &__radio {
  }
}
