.travel-page-author {
  flex-shrink: 0;
  width: 100%;
  padding: 30px;
  background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
  border-radius: 10px;
  border: 1px solid #FFFFFF;
  margin: 0;

  @media (max-width: 460px) {
    margin-top: 80px;
    width: 100%;
    padding: 20px;
    float: unset;
  }

  &__title {
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    color: #FFFFFF;

    @media (max-width: 460px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    margin-top: 20px;

    @media (max-width: 460px) {
      margin-top: 30px;
    }
  }
  &__avatar {
    flex-shrink: 0;
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;

    @media (max-width: 460px) {
      width: 100px;
      height: 100px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 2px solid #ffffff;
      object-fit: cover;
      object-position: center;
    }
    &--empty {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 5px;
      background-color: #fff;
      img {
        border-radius: 0;
        opacity: 0.2;
        scale: 0.7;
      }
    }
  }

  &__info {
    margin-left: 10px;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: #FFFFFF;

    @media (max-width: 460px) {
      font-size: 22px;
      line-height: 27px;
    }
  }
  &__name {
    margin: 0;
  }
  &__username {
    margin: 10px 0 0;
    opacity: 0.7;
  }
}
