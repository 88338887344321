.route-event-card {
  width: 440px;

  @media screen and (max-width: 460px) {
    width: 100%;
  }
  &--editable {
    cursor: pointer;
  }
  &__wrap {
    height: 269px;
    position: relative;
    display: flex;
    background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
    box-shadow: 0px 4px 24px -1px rgb(0 0 0 / 20%);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 10px;

    @media screen and (max-width: 460px) {
    }

    &:before,
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: calc(100% - 26px);
      position: absolute;
      z-index: 5;
      top: 13px;
      background: linear-gradient(to bottom, #ffffff, #5a5959);
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
  &__bg {
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      z-index: 4;
      display: block;
      height: 50%;
      width: 100%;
      pointer-events: none;
    }
    &:before {
      top: 0;
      border-radius: 10px 10px 0 0;
      border: 1px solid #ffffff;
      border-bottom: none;
    }
    &:after {
      bottom: 0;
      border-radius: 0 0 10px 10px;
      border: 1px solid #5a5959;
      border-top: none;
    }
  }
  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
  &__content {
    display: flex;
    flex-direction: column;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.1) 40%, rgba(255, 255, 255, 0.1) 60%, rgba(0, 0, 0, 0.9) 100%);
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
    padding: 10px;
    position: relative;
    z-index: 3;
    border-radius: 10px;
    width: 100%;
    text-decoration: none;
    @media (max-width: 460px) {
      width: 100%;
    }
  }
  &__name {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
  &__details {
    margin-top: auto;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 17px;

    @media screen and (max-width: 460px) {
      font-size: 12px;
      line-height: 15px;
      opacity: 1;
    }

    &:not(:last-of-type) {
      margin-bottom: 5px;
    }

    svg {
      display: block;
      margin-right: 8px;
      flex-shrink: 0;
    }
  }
}

.route-event-card-map {
  width: 100%;
  height: 100%;
  .ymaps-layers-pane {
    filter: grayscale(1);
  }
  .ymaps-2-1-79-areas-pane,
  .ymaps-2-1-79-ground-pane {
    filter: invert(100%) grayscale(1);
  }
  .ymaps-2-1-79-routerPoints-pane {
    display: none;
  }
}
