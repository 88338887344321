.route-events-page {
  padding: 30px 0;
  min-height: 100vh;
  background-color: #161C27;
  &__auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    span {
      margin-top: 10px;
    }
  }
  &__wrap {}
  &__head {}
  &__create {
    display: flex;
    justify-content: center;
    .btn {
      width: 240px;

      @media (max-width: 460px) {
        width: 100%;
      }
    }
  }
  &__list {
    margin-top: 20px;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;

    .route-event-card {
      margin-right: 20px;
      margin-bottom: 20px;

      @media (max-width: 460px) {
        margin-right: 0;
      }
    }
  }
  &__empty {
    margin: 30px 0;
  }
  &__loading {
    margin-bottom: 30px;
  }
}

.route-events-page-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  &__item {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 15px;
    }
    &--active {
      font-weight: 500;
      border-color: #ffffff;
      cursor: default;
    }
  }
}
