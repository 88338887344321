@use "../../colors";

.registration-form {
  padding: 30px;
  background: rgba(30, 30, 30, 0.8);
  backdrop-filter: blur(4px);
  border-radius: 5px;
  border: 1px solid #ffffff;
  color: #ffffff;
  width: 837px;

  @media (max-width: 900px) {
    width: 420px;
  }

  @media (max-width: 460px) {
    width: 100%;
    padding: 30px 15px;
  }

  &__title {
    font-weight: 500;
    font-size: 41px;
    line-height: 50px;
    opacity: 0.9;

    @media (max-width: 460px) {
      font-size: 26px;
      line-height: 32px;
      text-align: center;
    }
  }
  &__desc {
    @media (max-width: 460px) {
      text-align: center;
    }
  }
  &__subtitle {
    margin-top: 38px;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    opacity: 0.9;

    @media (max-width: 460px) {
      margin-top: 15px;
      font-size: 16px;
      padding: 0 20px 5px;
      text-align: center;
    }
  }
  &__col {
    width: 360px;

    @media (max-width: 900px) {
      width: 100%;
    }
  }
  &__errors {
    color: red;
    margin-top: 25px;

    @media (max-width: 460px) {
      margin-top: 20px;
    }
  }
  &__personal {
    margin-top: 10px;
    span {
      color: colors.$primary;
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-start;
    margin: 34px 0 0;
    @media (max-width: 460px) {
      margin-top: 25px;
      justify-content: center;
    }
  }
  &__item {
    width: 100%;
    margin: 25px 0 0;
    @media (max-width: 460px) {
      margin-top: 20px;
    }
    span {
      display: block;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      @media (max-width: 460px) {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 17px;
      }
    }
    .date-picker {
      &__selected {
        height: 54px;
        cursor: pointer;

        @media (max-width: 460px) {
          height: 40px;
        }
      }
      span {
        margin-bottom: 0;
      }
    }
    .select__wrap {
      @media (max-width: 460px) {
        height: 40px;
      }
      svg {
        @media (max-width: 460px) {
          top: 8px;
        }
      }
    }
    .select__search {
      input {
        border: none;
      }
    }
    input {
      display: block;
      width: 100%;
      height: 54px;
      padding: 0 15px;
      background-color: transparent;
      border: 1px solid #c3c3c3;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;

      &::-webkit-calendar-picker-indicator {
        filter: invert(100%);
      }

      @media (max-width: 460px) {
        height: 40px;
        font-weight: 400;
      }
      &:focus {
        border-color: #ffffff;
        outline: none;
      }
      &::placeholder {
        color: #c3c3c3;
      }
    }
    .desc {
      margin-top: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #C3C3C3;
    }
    &--password {
      position: relative;
      .password-control {
        top: 45px;
        @media (max-width: 460px) {
          top: 35px;
        }
      }
    }
    &--confirm {
      position: relative;
      .password-control {
        top: 17px;
        @media (max-width: 460px) {
          top: 12px;
        }
      }
    }
    &--error {
      input {
        border-color: colors.$primary;
      }
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 15px 0 0;

    @media (max-width: 900px) {
      flex-direction: column;
      margin-top: 0;
    }

    @media (max-width: 460px) {
      font-size: 12px;
      line-height: 17px;
    }

    &--passwords {
      .registration-form__col:last-child {
        margin-top: 30px;
        @media (max-width: 900px) {
          margin-top: 0;
        }
      }
    }
  }
}

.registration-form-personal-data-modal {
  padding-top: 20px;
  &__title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }
  &__content {
    margin-top: 20px;
    font-size: 14px;
    line-height: 18px;
  }
}
