@use "../../colors";

.profile-files-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  @media (max-width: 720px) {
    justify-content: space-between;
  }

  &__item {
    position: relative;
    width: 309px;
    height: 240px;
    margin-right: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    border-radius: 10px;
    @media (max-width: 1440px) {
      margin-right: 20px;
      margin-bottom: 20px;
    }
    @media (max-width: 720px) {
      width: calc(50% - 10px);
      margin-right: 0;
    }
    @media (max-width: 580px) {
      height: 200px;
    }
    @media (max-width: 460px) {
      width: calc(50% - 5px);
      height: 150px;
      margin-bottom: 10px;
    }

    &:nth-child(3n) {
      margin-right: 0;
      @media (max-width: 1440px) {
        margin-right: 20px;
      }
      @media (max-width: 720px) {
        margin-right: 0;
      }
    }
    img,
    video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }

    &:hover {
      .profile-files-list__remove {
        display: block;
      }
    }
  }
  &__remove {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;
    cursor: pointer;
    opacity: 0.5;
    transition: 0.2s linear;

    svg {
      fill: colors.$primary;
    }

    &:hover {
      opacity: 1;
    }
  }
}
