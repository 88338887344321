@use "../../colors";

.profile-create-event {
  &__wrap {
    padding: 67px 0;

    @media (max-width: 460px) {
      padding: 79px 0 70px;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 41px;
    line-height: 50px;

    @media (max-width: 1060px) {
      text-align: center;
    }

    @media (max-width: 460px) {
      font-size: 26px;
      line-height: 32px;
    }

    span {
      color: colors.$primary;
    }
  }
  &__row {
    width: 1185px;
    margin-top: 38px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 1300px) {
      width: 100%;
    }
    @media (max-width: 1060px) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__col {
    width: 465px;

    @media (max-width: 460px) {
      width: 100%;
    }
    &:not(:first-child) {
      @media (max-width: 460px) {
        margin-top: 40px;
      }
    }
  }
  &__subtitle {
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;

    @media (max-width: 460px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  &__form {
    margin-top: 25px;
  }

  .input {
    margin-top: 25px;
    &__label {
      display: block;
      width: 100%;
      span {
        display: block;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
    }
    &__field {
      position: relative;
      input {
        display: block;
        width: 100%;
        height: 54px;
        padding: 0 10px;
        background-color: transparent;
        border: 1px solid #ffffff;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #ffffff;
        &::placeholder {
          color: rgba(#ffffff, 0.5);
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
  .textarea {
    margin-top: 25px;
    &__label {
      display: block;
      width: 100%;
      span {
        display: block;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
    }
    textarea {
      resize: none;
      display: block;
      width: 100%;
      height: 162px;
      padding: 15px 10px;
      background-color: transparent;
      border: 1px solid #ffffff;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      &::placeholder {
        color: rgba(#ffffff, 0.5);
      }
      &:focus {
        outline: none;
      }
    }
  }
}
.profile-create-event-date-time {
  margin-top: 25px;

  &__label {
    display: block;
    width: 100%;
    span {
      display: block;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  }
  &__field {
    display: flex;
    align-items: center;
    width: 100%;
    height: 54px;
    padding: 0 10px;
    background-color: transparent;
    border: 1px solid #ffffff;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    cursor: pointer;
    > span {
      margin: 0 auto 0 0;
    }
    .date-picker {
      &__wrap {
        width: 320px;
        left: 50%;
        top: unset;
        bottom: calc(100% + 4px);
        transform: translateX(-50%);
      }
      &__selected {
        justify-content: center;
        border-color: #565656;
        height: 34px;
        margin-right: 10px;
        background: #565656;


      }
      &__value {
        flex-grow: unset;
        display: flex;
        margin: 0;
        font-size: 14px;
        line-height: 1.5714285714285714;
      }
      &__icon {
        display: none;
      }
      &--active {
        .date-picker__selected {
          //border: 1px solid #ffffff;
          outline: 2px solid #ffffff;
        }
      }
    }
  }
  &__date {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 132px;
    height: 34px;
    background-color: #565656;
    color: #ffffff;
    border-radius: 5px;
    padding: 0 20px;
  }
  &__time {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 76px;
    height: 34px;
    background-color: #565656;
    color: #ffffff;
    border-radius: 5px;
  }
}
.profile-create-event-date-time-picker {
  &--active {}
  &__wrap {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    z-index: 10;
    background-color: #201E1E;
    border: 1px solid colors.$primary;
    border-radius: 4px;
    .react-calendar {
      width: 100%;
      border: 1px solid transparent;
      background-color: #201E1E;
      &--selectRange {
        .react-calendar__tile {
          &:enabled:hover,
          &:enabled:focus {
            background-color: transparent;
            border: 1px solid colors.$primary;
          }
          &:disabled {
            background-color: transparent;
            opacity: 0.1;
          }
          &--range {
            background-color: colors.$primary;
          }
          &--rangeStart {
            background-color: colors.$primary;
          }
          &--rangeEnd {
            background-color: colors.$primary;
          }
          &--hover {
            background: colors.$primary;
            border: 1px solid colors.$primary;
          }
        }
      }
      &__tile {
        border: 1px solid transparent;
        &:enabled:hover,
        &:enabled:focus {
          background-color: transparent;
          border: 1px solid colors.$primary;
        }
        &:disabled {
          background-color: transparent;
          opacity: 0.1;
        }
        &--now {
          background-color: rgba(colors.$primary, 0.5);
          border: 1px solid colors.$primary;
        }
        &--active,
        &--hasActive {
          background-color: colors.$primary;
        }
      }
      &__month-view__days__day {
        color: #ffffff;
        &--neighboringMonth {
          color: rgba(#ffffff, 0.3);
        }
      }
      &__year-view__months__month,
      &__decade-view__years__year {
        color: #ffffff;
      }
      &__navigation {
        &__arrow {
          &:enabled:hover,
          &:enabled:focus {
            background-color: colors.$primary;
          }
          &:disabled {
            background-color: transparent;
            opacity: 0.1;
          }
        }
        &__label {
          &:enabled:hover,
          &:enabled:focus,
          &:disabled:hover,
          &:disabled:focus {
            background-color: #201E1E;
          }
        }
        button {
          min-width: 44px;
          background: none;
          color: #fff;
          font-size: 16px;
        }
      }
    }
  }
  &__top {
    position: relative;
    display: flex;
    align-items: flex-start;
    //border: 1px solid colors.$primary;
    //border-radius: 4px 4px 0 0;
    height: 282px;
    overflow: hidden;
  }
  &__hours {
    //position: absolute;
    //right: 50px;
    //top: 0;
    width: 50%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #FF5900 #201e1e;
    //border-left: 1px solid colors.$primary;
    /* Работает в Chrome, Edge и Safari */
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #201e1e;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #FF5900;
      border-radius: 20px;
      border: 3px solid #FF5900;
    }
  }
  &__minutes {
    //position: absolute;
    //right: 0;
    //top: 0;
    width: 50%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #FF5900 #201e1e;
    border-left: 1px solid colors.$primary;
    /* Работает в Chrome, Edge и Safari */
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #201e1e;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #FF5900;
      border-radius: 20px;
      border: 3px solid #FF5900;
    }
  }
  &__time {
    width: 100%;
    display: flex!important;
    justify-content: center;
    padding: 5px 0;
    margin: 0!important;
    cursor: pointer;
    &--active {
      background-color: colors.$primary;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid colors.$primary;
  }
  &__close {
    padding: 5px;
    cursor: pointer;
  }
}
.profile-create-event-geo {
  margin-top: 25px;
  &__label {
    display: block;
    width: 100%;
    span {
      display: block;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  }
  &__address {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  &__footer {
    .btn {
      width: 200px;
      height: 40px;
      font-size: 16px;
      line-height: 39px;
      text-transform: none;
      border: none;
      border-radius: 59px;
    }
  }
}

.create-event-map {
  margin-top: 25px;
}
