.dtp-page-content {
  width: 700px;
  margin-left: 120px;

  @media (max-width: 1300px) {
    margin-left: 30px;
    flex-grow: 1;
  }

  @media (max-width: 1000px) {
    margin-left: 0;
    width: 100%;
  }

  @media (max-width: 460px) {
    width: 100%;
    order: 1;
    margin-left: 0;
  }

  &__title {
    margin: 0;
    font-weight: 500;
    font-size: 41px;
    line-height: 50px;
    color: #FFFFFF;

    @media (max-width: 460px) {
      font-size: 26px;
      line-height: 32px;
    }
  }
  &__author {
    margin: 5px 0 25px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
    opacity: 0.5;

    @media (max-width: 460px) {
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 15px;
    }
  }
  &__text {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #FFFFFF;
    white-space: pre-wrap;

    @media (max-width: 460px) {
      margin-bottom: 20px;
    }
  }
  img {
    margin: 0 0 25px;
    display: block;
    width: 100%;
  }
}
