.about-page-team {
  margin-top: 120px;

  @media (max-width: 460px) {
    margin-top: 80px;
  }
  &__wrap {
    @media (max-width: 1440px) {
      padding: 0;
    }
  }
  &__title {
    padding-bottom: 20px;
    font-weight: 500;
    font-size: 40px;
    line-height: 49px;
    text-transform: uppercase;
    color: #FFFFFF;
    border-bottom: 1px solid #FFFFFF;

    @media (max-width: 1440px) {
      margin: 0 50px;
    }
    @media (max-width: 1000px) {
      margin: 0 30px;
    }
    @media (max-width: 700px) {
      margin: 0 20px;
    }
    @media (max-width: 460px) {
      padding-bottom: 10px;
      font-size: 32px;
      line-height: 39px;
      margin: 0 10px;
    }
  }
  &__list {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    @media (max-width: 1440px) {
      padding: 0 50px 10px;
      overflow-x: auto;
    }

    @media (max-width: 1000px) {
      padding: 0 30px 10px;
    }
    @media (max-width: 700px) {
      padding: 0 20px 10px;
    }

    @media (max-width: 460px) {
      margin-top: 36px;
      padding: 0 10px 10px;
    }
  }
  &__item {
    width: 449px;
    @media (max-width: 1440px) {
      margin-right: 30px;
    }

    @media (max-width: 700px) {
      margin-right: 20px;
    }

    @media (max-width: 460px) {
      width: 285px;
      margin-right: 15px;
    }
    &:last-child {
      @media (max-width: 1440px) {
        margin-right: 0;
      }
    }
    img {
      display: block;
      width: 449px;
      height: 449px;
      object-fit: cover;

      @media (max-width: 460px) {
        width: 285px;
        height: 285px;
      }
    }
    p {
      margin: 20px 0 0;
      padding-top: 20px;

      font-weight: 500;
      font-size: 32px;
      line-height: 39px;
      color: #FFFFFF;
      border-top: 1px solid #FFFFFF;

      @media (max-width: 460px) {
        margin-top: 10px;
        padding-top: 15px;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #FFFFFF;
      }
    }
  }
}
