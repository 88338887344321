@use "../../colors";

.profile-create-route-event {
  &.container {
    @media (max-width: 460px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__wrap {
    padding: 67px 0;

    @media (max-width: 460px) {
      padding: 79px 0 70px;
    }
    &--preview {
      .profile-create-event-date-time__field {
        cursor: default;
      }
      .select__wrap {
        cursor: default;
      }
    }
  }
  &__title {
    font-weight: 500;
    font-size: 41px;
    line-height: 50px;

    @media (max-width: 1060px) {
      text-align: center;
    }

    @media (max-width: 460px) {
      font-size: 26px;
      line-height: 32px;
    }

    span {
      color: colors.$primary;
    }
  }
  &__desc {
    margin-top: 5px;
    font-size: 16px;
    line-height: 20px;
    color: colors.$primary;

    @media (max-width: 460px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  &__subtitle {
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;

    @media (max-width: 1060px) {
      //text-align: center;
    }

    @media (max-width: 460px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
  &__form {
    flex-shrink: 0;
    width: 465px;

    @media (max-width: 1040px) {
      width: 400px;
    }
    @media (max-width: 700px) {
      width: 420px;
    }
    @media (max-width: 520px) {
      width: 100%;
    }
    &:not(:first-child) {
      @media (max-width: 460px) {
        margin-top: 40px;
      }
    }
  }
  &__item {
    display: block;
    margin: 25px 0 0;
    position: relative;

    @media (max-width: 640px) {
      width: 100%;
      margin: 20px 0 0;
    }
    span {
      display: block;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      @media (max-width: 640px) {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 17px;
      }
    }
    input {
      display: block;
      width: 100%;
      height: 54px;
      padding: 0 15px;
      background-color: transparent;
      border: 1px solid #ffffff;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;

      &:focus {
        border-color: #ffffff;
        outline: none;
      }
      &::placeholder {
        color: #c3c3c3;
      }
    }
    .desc {
      margin-top: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #C3C3C3;
    }
    .select {
      input {
        border: none;
      }
    }
    &--error {
      input {
        border-color: colors.$primary;
      }
    }
  }
  &__count {
    font-size: 14px;
    line-height: 16px;
    margin-top: 5px;
    color: colors.$primary;
  }
  &__row {
    display: flex;

    @media (max-width: 840px) {
      flex-direction: column;
    }
  }
  &__map {
    margin-left: 60px;
    height: 100%;
    width: calc(100% - 525px);

    @media (max-width: 1400px) {
      margin-left: 30px;
      width: calc(100% - 495px);
    }
    @media (max-width: 1040px) {
      margin-left: 20px;
      width: calc(100% - 420px);
    }
    @media (max-width: 840px) {
      margin-left: 0;
      width: 100%;
    }
  }
  &__footer {
    margin-top: 30px;
    display: flex;
    align-items: center;

    @media (max-width: 520px) {
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }
  &__submit {
    margin-right: 10px;
    margin-bottom: 10px;
    @media (max-width: 460px) {
      margin-right: 0;
      margin-bottom: 0;
      width: 100%;
    }
    .btn {
      width: auto;
    }
  }
  &__create {
    margin-right: 10px;
    margin-bottom: 10px;

    @media (max-width: 460px) {
      margin-top: 20px;
      margin-right: 0;
      width: 100%;
    }
  }
  &__delete {
    margin-bottom: 10px;
    @media (max-width: 460px) {
      margin-top: 20px;
      margin-bottom: 0;
      width: 100%;
    }
  }
}
.profile-create-route-event-date-time {
  margin-top: 25px;

  .profile-create-event-date-time__field {
    span {
      @media (max-width: 460px) {
        display: none;
      }
    }
  }
  &__label {
    display: block;
    width: 100%;
    > span {
      display: block;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  }
  &__field {
    display: flex;
    align-items: center;
    width: 100%;
    height: 54px;
    padding: 0 10px;
    background-color: transparent;
    border: 1px solid #ffffff;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    cursor: pointer;
    > span {
      margin: 0 auto 0 0;
    }
    .date-picker {
      &__wrap {
        width: 320px;
        left: 50%;
        top: unset;
        bottom: calc(100% + 4px);
        transform: translateX(-50%);
      }
      &__selected {
        justify-content: center;
        border-color: #565656;
        height: 34px;
        margin-right: 10px;
        background: #565656;


      }
      &__value {
        flex-grow: unset;
        display: flex;
        margin: 0;
        font-size: 14px;
        line-height: 1.5714285714285714;
      }
      &__icon {
        display: none;
      }
      &--active {
        .date-picker__selected {
          //border: 1px solid #ffffff;
          outline: 2px solid #ffffff;
        }
      }
    }
  }
  &__date {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 132px;
    height: 34px;
    background-color: #565656;
    color: #ffffff;
    border-radius: 5px;
    padding: 0 20px;
  }
  &__time {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 76px;
    height: 34px;
    background-color: #565656;
    color: #ffffff;
    border-radius: 5px;
  }
}

.create-route-event-map {
  width: 100%;
  margin-top: 25px;

  .ymaps-layers-pane {
    filter: grayscale(1);
  }
  .ymaps-2-1-79-areas-pane,
  .ymaps-2-1-79-ground-pane {
    filter: invert(100%) grayscale(1);
  }
  .ymaps-2-1-79-routerPoints-pane {
    display: none;
  }
  &__info {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: colors.$primary;

    @media (max-width: 460px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  &__wrap {
    width: 100%;
    height: 545px;

    @media (max-width: 640px) {
      height: 100vw;
    }
  }
  &__footer {
    margin-top: 20px;
    display: flex;

    @media (max-width: 600px) {
      width: 100%;
    }

    @media (max-width: 460px) {
      flex-wrap: wrap;
    }
  }
  &__to-yandex {
    display: flex;
    flex-wrap: wrap;

  }
  &__link {
    &.btn {
      display: flex;
      align-items: center;
      text-decoration: none;
      border-radius: 0;
      border: none;
      margin-bottom: 10px;

      @media (max-width: 460px) {
        width: 100%;
      }
      &:hover {
        color: #ffffff;
      }
      svg {
        margin-left: 10px;
      }
    }
    &:not(:last-child) {
      margin-right: 20px;

      @media (max-width: 460px) {
        margin-right: 0;
        margin-top: 20px;
      }
    }
  }
  &__name {
    @media (max-width: 600px) {
      flex-grow: 1;
    }
    @media (max-width: 460px) {
      width: 100%;
    }
    input {
      width: 300px;
      height: 50px;
      border-radius: 4px;
      background-color: transparent;
      padding: 0 10px;
      border: 1px solid colors.$primary;
      color: #fff;
      @media (max-width: 600px) {
        width: 100%;
      }

      &::placeholder {
        color: rgba(#fff, 0.6);
      }
      &:focus {
        outline: none;
      }
    }
  }
  &__add {
    margin-left: 10px;
    @media (max-width: 460px) {
      margin-left: 0;
      margin-top: 10px;
      width: 100%;
    }
    .btn {
      @media (max-width: 460px) {
        width: 100%;
      }
      &:disabled {
        opacity: 0.6;
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.profile-create-route-event-waypoints {
  margin-top: 20px;
  &__title {
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  &__list {
    border: 1px solid #ffffff;
    border-radius: 4px;
  }
  &__item {
    display: flex;
    align-items: center;
    padding-right: 4px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    svg {
      display: block;
    }
    &:hover {
      background-color: rgba(#fff, 0.05);
    }
  }
  &__name {
    flex-grow: 1;
    padding: 8px 10px;
  }
  &__up {
    flex-shrink: 0;
    margin-left: 5px;
    cursor: pointer;
  }
  &__down {
    flex-shrink: 0;
    margin-left: 5px;
    cursor: pointer;
  }
  &__remove {
    flex-shrink: 0;
    margin-left: 5px;
    cursor: pointer;
  }
}

.route-selected-event-card {
  @media screen and (max-width: 460px) {
    width: calc(100% - 15px);
  }
  &--preview {
    .route-selected-event-card__wrap {
      height: 435px;
    }
  }
  &__wrap {
    width: 335px;
    height: 465px;
    position: relative;
    flex-direction: column;
    display: flex;
    background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 10px;

    @media screen and (max-width: 460px) {
      flex-direction: column;
      width: 100%;
      height: unset;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: calc(100% - 26px);
      position: absolute;
      z-index: 3;
      top: 13px;
      background: linear-gradient(to bottom, #ffffff, #5a5959);
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
  img{
    height: 215px;
    position: relative;
    border-radius: 10px 10px 0 0;
    border: none;
    flex-shrink: 0;
    object-fit: cover;
    object-position: center;
  }
  &__type {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #FFFFFF;
    opacity: 0.8;
  }
  &__content{
    background: #373737;
    border-radius: 0 0 10px 10px;
    border: none;
    //height: 80%;

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
    z-index: 1;
  }
  &__bg {
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      z-index: 2;
      display: block;
      height: 50%;
      width: 100%;
      pointer-events: none;
    }
    &:before {
      top: 0;
      border-radius: 10px 10px 0 0;
      border: 1px solid #ffffff;
      border-bottom: none;
    }
    &:after {
      bottom: 0;
      border-radius: 0 0 10px 10px;
      border: 1px solid #5a5959;
      border-top: none;
    }
  }
  &__fav {
    position: absolute;
    top: 235px;
    right: 20px;
    z-index: 12;
    cursor: pointer;

    @media screen and (max-width: 460px) {
      right: 16px;
    }

    svg {
      display: block;
      stroke: colors.$primary;
      fill: transparent;
    }
    &--active {
      svg {
        fill: colors.$primary;
      }
    }
  }
  &__details {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    opacity: 0.8;

    @media screen and (max-width: 460px) {
      font-size: 12px;
      line-height: 15px;
      opacity: 1;
    }

    &:not(:last-of-type) {
      margin-bottom: 5px;
    }

    svg {
      display: block;
      margin-right: 8px;
      flex-shrink: 0;
    }
  }
  &__desc {
    display: flex;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: colors.$primary;
    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  &__footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    padding: 0;
    @media screen and (max-width: 460px) {
      margin-top: 20px;
    }
    .btn {
      height: 34px;
    }
  }
}

.profile-create-route-event-selected {
  margin-top: 20px;
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    @media (max-width: 1400px) {
      flex-wrap: nowrap;
      width: 100%;
      overflow: scroll;
    }

    .route-selected-event-card {
      margin: 0 10px 10px 0;
    }
  }
}
.profile-create-route-event-includes {
  margin-top: 20px;
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    @media (max-width: 1400px) {
      flex-wrap: nowrap;
      width: 100%;
      overflow: scroll;
    }
    .route-selected-event-card {
      margin: 0 10px 10px 0;
    }
  }
}
.route-selected-event-card-modal {
  padding-top: 16px;
  &__title {
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
  }
  &__wrap {
    height: 60vh;
    overflow: auto;
    margin-top: 14px;
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 1.2;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #201e1e;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #FF5900;
      border-radius: 20px;
      border: 3px solid #FF5900;
    }
  }
}

.profile-create-route-event-delete-modal {
  &__text {}
  &__footer {
    margin-top: 20px;
    display: flex;
  }
  &__confirm {}
  &__cancel {
    margin-left: 20px;
  }
}
