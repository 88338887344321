@use "../../colors";

.main-map {
  &__title {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 41px;
    line-height: 50px;
    margin-bottom: 25px;

    @media screen and (max-width: 940px) {
      display: inline-block;
    }
    @media screen and (max-width: 460px) {
      font-size: 26px;
      line-height: 32px;
      margin-bottom: 15px;
      padding: 0 15px;
    }

    &:before {
      content: '';
      display: block;
      margin-right: 22px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #76FF46;

      @media screen and (max-width: 940px) {
        display: inline-block;
        margin-bottom: 4px;
      }

      @media screen and (max-width: 460px) {
        margin-right: 10px;
        width: 10px;
        height: 10px;
      }
    }

    span {
      color: colors.$primary;
    }
  }
}
