@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.eot");
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("../fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat-Regular.woff") format("woff"), url("../fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Medium.eot");
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url("../fonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat-Medium.woff") format("woff"), url("../fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBold.eot");
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
    url("../fonts/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat-SemiBold.woff") format("woff"), url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
