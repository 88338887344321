@use "../colors";

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  color: #fff;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
  }

  &__logo {
    display: flex;
    justify-content: flex-start;
    width: 130px;

    @media screen and (max-width: 1100px) {
      order: 2;
      width: unset;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    @media screen and (max-width: 680px) {
      position: static;
      transform: translateX(0);
    }

    img {
      display: block;
      width: 97px;
    }
  }

  &__login {
    display: flex;
    align-items: center;
    @media screen and (max-width: 1100px) {
      justify-content: center;
      order: 3;
    }
    @media screen and (max-width: 680px) {
    }
    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
      display: block;
      margin-right: 6px;
      @media screen and (max-width: 460px) {
        width: 15px;
        height: 15px;
      }
    }
    a {
      font-weight: 600;
      font-size: 14px;
      color: colors.$primary;
      text-decoration: none;

      &:hover {
        color: colors.$primary;
      }
    }
    span {
      display: block;
      margin: 0 6px;
      color: colors.$primary;
    }
  }
}

.mobile-menu{
  z-index: 2;
  &-logo{
    opacity: 0;
    @media screen and (max-width: 1100px){
      opacity: 0.3;
      width: 180px;
      padding: 10px 0;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &_login{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  @media screen and (max-width: 1100px){
    position: fixed;
    background: #201E1E;
    left: 0;
    bottom: 0;
    top: 0;
    width: 100%;
  }
}

.header-nav {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 460px) {
  }

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    list-style: none;

    @media screen and (max-width: 1100px){
      display: block;
      list-style: none;
      width: 400px;
      padding: 60px 40px;
      text-align: center;
    }
    @media screen and (max-width: 460px){
      width: 100%;
    }
  }
  &__item {
    position: relative;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 140%;

    &:not(:last-child) {
      margin-right: 48px;

      @media screen and (max-width: 1200px) {
        margin-right: 28px;
      }
    }
    &--disabled {
      cursor: not-allowed;
      &:hover {
        .header-nav__tooltip {
          opacity: 1;
        }
      }
      span {
        opacity: 0.5;
      }
    }
    @media screen and (max-width: 1100px){
      margin: 0!important;
      border-bottom: 1px solid colors.$primary;
    }
    a,
    span {
      @media screen and (max-width: 1100px) {
        display: block;
        padding: 20px;
      }
    }
  }
  &__tooltip {
    position: absolute;
    top: calc(100% + 5px);
    right: 50%;
    z-index: 2;
    transform: translateX(50%);
    text-align: center;
    padding: 15px;
    border: 1px solid #ffffff;
    text-transform: none;
    pointer-events: none;
    opacity: 0;
    background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    transition: 0.2s ease-in;

    &:before {
      content: '';
      display: block;
      border: 4px solid #ffffff;
      border-top: none;
      border-left-color: transparent;
      border-right-color: transparent;
      position: absolute;
      top: -5px;
      left: calc(50% - 4px);
    }
  }
  a {
    color: inherit;
    text-decoration: none;

    &.active {
      font-weight: 600;
    }
  }
  &__count {
    position: absolute;
    top: -10px;
    right: -10px;
    margin: 0;
    padding: 2px 5px;
    font-size: 12px;
    line-height: 12px;
    background-color: #ff5900;
    border-radius: 12px;
  }
}

.header-user {
  display: flex;
  justify-content: end;

  @media screen and (max-width: 1100px) {
    order: 3;
  }
  &__profile {
    position: relative;
    padding: 5px 0 5px 50px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1100px){
      padding: 5px 0;
    }

    @media screen and (max-width: 460px) {
      display: none;
    }

    &:hover {
      .header-user__drop svg {
        transform: rotateZ(180deg);
      }
      .header-user__nav {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  &__drop {
    svg {
      transition: 0.2s linear;
    }
  }
  &__avatar {
    display: block;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid colors.$primary;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &--empty {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 5px;
      background-color: #fff;
      img {
        opacity: 0.2;
        scale: 0.7;
      }
    }
  }
  &__nav {
    position: absolute;
    right: 0;
    top: 100%;
    width: 250px;
    padding: 17px 0;
    background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    border: 1px solid #FFFFFF;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s linear;

  }
  &__link {
    background-color: rgba(#222222, 0);
    display: flex;
    align-items: center;
    height: 39px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #FFFFFF;
    text-decoration: none;
    padding: 0 14px;
    transition: 0.2s linear;
    cursor: pointer;

    svg {
      display: block;
      margin-right: 8px;
    }

    &:hover {
      color: #FFFFFF;
      background-color: rgba(#222222, 1);
    }
  }
}
