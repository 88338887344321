.profile-events {
  &__head {
    display: flex;
    align-items: flex-end;

    @media (max-width: 460px) {
      align-items: center;
      margin-top: 12px;
    }
  }

  &__add {
    margin-left: auto;
    width: 200px;

    @media (max-width: 460px) {
      width: 126px;
      height: 40px;
    }
  }
  &__empty {
    margin: 30px 0;
  }
  &__loading {
    margin-bottom: 30px;
  }
}
