.policy-page {
  &__title {
    @media screen and (max-width: 460px) {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
  &__content {
    p {
      @media screen and (max-width: 460px) {
        font-size: 14px;
        line-height: 1.25;
      }
    }
  }
}
