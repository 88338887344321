.travel-page-about {
  margin: 5px auto 0;

  @media (max-width: 1200px) {
    margin-top: 30px;
  }

  @media (max-width: 460px) {
    width: 100%;
    padding: 0 10px;
    margin-top: 30px;
  }

  &__info {
    font-weight: 400;
    color: #FFFFFF;

    @media (max-width: 460px) {
      margin-top: 30px;
      width: 100%;
    }
  }
  &__duration {
    margin: 0;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    opacity: 0.8;
  }
  &__desc {
    margin: 25px 0 0;
    font-size: 16px;
    line-height: 140%;
    text-align: justify;
    white-space: pre-wrap;

    @media (max-width: 460px) {
      margin-top: 20px;
    }
  }
  //ymaps{
  //  mix-blend-mode: color-burn;
  //  background-image: linear-gradient(#5b58ff, #8d6bff), linear-gradient(#b29bff, #595dc8);
  //  background-image: linear-gradient(#2e1a68, #000000), linear-gradient(#fff100, #f8feb8);
  //  background-blend-mode: exclusion;
  //}
}
