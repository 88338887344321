.profile-garage {
  &__head {
    display: flex;
    align-items: flex-end;

    @media (max-width: 460px) {
      align-items: center;
      margin-top: 12px;
    }
  }
  &__add {
    margin-left: auto;
    width: 256px;

    @media (max-width: 460px) {
      width: 177px;
      height: 40px;
    }
  }
}
