.modal-full {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  overflow: hidden;
  background-color: #201e1e;

  &__wrap {
    position: relative;
    height: 100%;
    overflow: auto;
    padding: 0;

    @media (max-width: 460px) {
      width: 100%;
      padding: 15px;
    }
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    @media (max-width: 460px) {
      top: 30px;
      right: 15px;
    }
  }
}
