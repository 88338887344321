@use "../../colors";

.dtp-list-page {
  &__wrap {}
  &__head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 860px) {
      display: block;
    }

    &:before {
      content: '';
      display: block;
      width: 300px;

      @media (max-width: 1200px) {
        display: none;
      }
    }
  }
  &__title {
    font-weight: 500;
    font-size: 41px;
    line-height: 50px;
    color: #FFFFFF;
    text-align: center;

    @media (max-width: 600px) {
      text-align: left;
    }

    @media (max-width: 460px) {
      font-size: 26px;
      line-height: 32px;
    }
  }
  &__city {
    flex-shrink: 0;
    width: 300px;
    @media (max-width: 860px) {
      margin: 0 auto;
    }

    @media (max-width: 600px) {
      margin: 0;
    }
    @media (max-width: 360px) {
      width: 100%;
    }
    //.select__options {
    //  border-color: colors.$primary;
    //  overflow-x: hidden;
    //}
    .select__item {
      height: unset;
      padding: 10px 20px;
    }
  }
  &__content {
    width: 920px;
    margin: 80px auto 0;

    @media (max-width: 1020px) {
      width: 100%;
    }

    @media (max-width: 460px) {
      margin-top: 30px;
    }
  }
}
