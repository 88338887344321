@use "../../colors";

.about-page-app {
  margin-top: 12px;

  @media (max-width: 460px) {
    margin-top: 40px;
  }

  &__wrap {
    display: flex;
    align-items: center;

    @media (max-width: 980px) {
      display: block;
      margin-bottom: 60px;
    }

    @media (max-width: 460px) {
      padding: 0 10px;
      margin-bottom: 40px;
    }
  }
  &__col {
    width: 50%;

    @media (max-width: 980px) {
      width: 100%;
    }
  }
  &__text {
    width: 453px;
    font-weight: 500;
    font-size: 32px;
    line-height: 125%;
    color: #FFFFFF;

    @media (max-width: 980px) {
      text-align: center;
      width: 100%;
    }

    @media (max-width: 460px) {
      text-align: left;
      width: 100%;
      font-size: 24px;
      line-height: 125%;
    }

    span {
      color: colors.$primary;
    }
  }
  &__links {
    display: flex;
    margin-top: 80px;

    @media (max-width: 980px) {
      margin-top: 40px;
      justify-content: center;
    }

    @media (max-width: 460px) {
      justify-content: space-between;
    }

    a {
      position: relative;
      &:not(:last-child) {
        margin-right: 30px;

        @media (max-width: 460px) {
          margin-right: unset;
        }
      }
      &:hover {
        .about-page-app__tooltip {
          opacity: 1;
        }
      }
      img {
        display: block;
        width: 208px;

        @media (max-width: 460px) {
          width: 145px;
        }
      }
    }
  }
  &__tooltip {
    position: absolute;
    top: calc(100% + 5px);
    right: 50%;
    width: 100%;
    z-index: 2;
    transform: translateX(50%);
    text-align: center;
    padding: 15px;
    border: 1px solid #ffffff;
    text-transform: none;
    pointer-events: none;
    opacity: 0;
    background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    transition: 0.2s ease-in;

    &:before {
      content: '';
      display: block;
      border: 4px solid #ffffff;
      border-top: none;
      border-left-color: transparent;
      border-right-color: transparent;
      position: absolute;
      top: -5px;
      left: calc(50% - 4px);
    }
  }
  &__images {
    position: relative;

    @media (max-width: 980px) {
      margin: 40px auto 0;
      width: 350px;
    }

    @media (max-width: 660px) {
      width: 194px;
    }
  }
  &__img {
    position: relative;
    z-index: 2;

    @media (max-width: 660px) {
      width: 100%;
    }
  }
  &__img-deco {
    position: absolute;
    top: 115px;
    left: 260px;
    z-index: 1;
    width: 225px;

    @media (max-width: 660px) {
      width: 114px;
      top: 52px;
      left: 133px;
    }
  }
}
