.profile-info {
  position: absolute;
  top: -72px;
  left: 0;
  z-index: 6;

  @media (max-width: 1240px) {
    position: relative;
    top: unset;
    //left: calc((100% - 280px) / 2);
    left: unset;
  }

  &__wrap {
    width: 373px;
    border-radius: 10px;
    border: 1px solid #ffffff;
    background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 44px;

    @media (max-width: 1240px) {
      width: 320px;
      padding: 25px;
    }

    @media (max-width: 460px) {
      width: 280px;
      padding: 25px;
    }
  }
  &__avatar {
    width: 172px;
    height: 172px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    overflow: hidden;

    @media (max-width: 1240px) {
      width: 100px;
      height: 100px;
    }

    @media (max-width: 460px) {
      width: 87px;
      height: 87px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &--empty {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 5px;
      background-color: #fff;
      img {
        opacity: 0.2;
        scale: 0.7;
      }
    }
    &--active {
      cursor: pointer;
    }
  }
  &__name {
    margin: 20px 0 0;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    text-align: center;

    @media (max-width: 460px) {
      font-size: 20px;
      line-height: 24px;
      margin-top: 14px;
      padding-bottom: 5px;
    }
    p {
      display: block;
      margin: 0;
    }
  }
  &__progress {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    opacity: 0.7;

    span {
      margin-top: 5px;
      display: block;
    }
  }
  &__footer {
    margin-top: 40px;
    width: 100%;

    @media (max-width: 460px) {
      margin-top: 20px;
    }

    button {
      width: 100%;

      @media (max-width: 460px) {
        text-transform: unset;
      }

      &:not(:last-child) {
        margin-bottom: 20px;

        @media (max-width: 460px) {
          margin-bottom: 15px;
        }
      }
    }
  }
}
