@use "../../colors";

.dtp-page-aside {
  flex-shrink: 0;
  width: 360px;

  @media (max-width: 1300px) {
    width: 340px;
  }

  @media (max-width: 1000px) {
    margin-top: 80px;
    width: 100%;
    order: 2;
  }

  &__title {
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    color: colors.$primary;
  }
  &__list {
    margin-top: 54px;

    @media (max-width: 1000px) {
      margin-top: 25px;
    }
  }
  &__item {
    @media (max-width: 1000px) {
      display: flex;
      flex-direction: column-reverse;
    }
    &:not(:last-child) {
      margin-bottom: 40px;

      @media (max-width: 1000px) {
        margin-bottom: 20px;
      }
    }
  }
  &__text {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: colors.$primary;
    }
  }
  &__date {
    display: block;
    margin: 20px 0 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    opacity: 0.7;

    @media (max-width: 1000px) {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }
}
