.profile-edit-page {
  padding-bottom: 90px;

  @media (max-width: 460px) {
    padding-bottom: 0;
  }
  &__wrap {
    margin-top: 60px;

    @media (max-width: 460px) {
      margin-top: 40px;
    }
  }
}

.profile-avatar-upload-modal {
  &__wrap {
    padding-top: 20px;
  }
  &__empty {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__image {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;

    //img {
    //  display: block;
    //  width: 100%;
    //}
  }
  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(#000, 0.7);
  }
  &__footer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .btn {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
