@use "sass:math";

.hamburger {
  order: 1;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  position: relative;
  z-index: 3;

  @media screen and (max-width: 460px) {
    position: absolute;
    right: 10px;
    top: 20px;
  }
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #ffffff;
}

.hamburger-box {
  width: 36px;
  height: 36px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: 0px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 36px;
  height: 2px;
  background-color: #ffffff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -12px;
}

.hamburger-inner::after {
  bottom: -12px;
}

$hamburger-layer-width: 40px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 10px !default;

.hamburger--slider {
  .hamburger-inner {
    top: math.div($hamburger-layer-height, 2) + 5;

    &::before {
      top: $hamburger-layer-height + $hamburger-layer-spacing;
      transition-property: transform, opacity;
      transition-timing-function: ease;
      transition-duration: 0.15s;
    }

    &::after {
      top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
    }
  }

  &.is-active {
    .hamburger-inner {
      $y-offset: $hamburger-layer-spacing + $hamburger-layer-height;

      transform: translate3d(0, $y-offset, 0) rotate(45deg);

      &::before {
        transform: rotate(-45deg) translate3d(math.div($hamburger-layer-width, -7), $hamburger-layer-spacing * -1, 0);
        opacity: 0;
      }

      &::after {
        transform: translate3d(0, $y-offset * -2, 0) rotate(-90deg);
      }
    }
  }
}
