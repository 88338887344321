@use "../../colors";

.profile-edit-details {
  &__title {
    width: 100%;
    font-weight: 500;
    font-size: 41px;
    line-height: 50px;

    @media (max-width: 640px) {
      font-size: 26px;
      line-height: 32px;
      opacity: 0.9;
    }
  }
  &__wrap {
    display: flex;

    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }
  &__aside {
    padding-top: 25px;
  }
  &__avatar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 2px solid colors.$primary;
    background-color: #C9C9C9;
    overflow: hidden;
    cursor: pointer;

    @media (max-width: 640px) {
      width: 110px;
      height: 110px;
    }

    svg {
      display: block;

      @media (max-width: 640px) {
        width: 24px;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &:hover {
      .profile-edit-details__avatar-hover {
        opacity: 1;
      }
    }
    &-hover {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.7);
      pointer-events: none;
      opacity: 0;
      transition: 0.2s linear;

      svg {
        display: block;
        width: 32px;
        height: 32px;
      }
    }
  }
  &__row {
    display: flex;
    align-items: flex-end;

    @media (max-width: 640px) {
      flex-direction: column;
      align-items: unset;
    }
    &:not(:last-child) {
      margin-bottom: 25px;

      @media (max-width: 640px) {
        margin-bottom: 0;
      }
    }
    .profile-edit-details__item {
      &:first-child {
        @media (max-width: 1200px) {
          margin-left: 0;
        }
      }
    }
  }
  &__item {
    width: 360px;
    margin: 25px 0 0 50px;
    position: relative;

    @media (max-width: 640px) {
      width: 100%;
      margin: 20px 0 0;
    }
    span {
      display: block;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      @media (max-width: 640px) {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 17px;
      }
    }
    input {
      display: block;
      width: 100%;
      height: 54px;
      padding: 0 15px;
      background-color: transparent;
      border: 1px solid #c3c3c3;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;

      &::-webkit-calendar-picker-indicator {
        filter: invert(100%);
      }

      &:focus {
        border-color: #ffffff;
        outline: none;
      }
      &::placeholder {
        color: #c3c3c3;
      }
    }
    &--head {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &--confirm {
      background-color: colors.$primary;
      font-size: 14px;
      padding: 2px 8px;
      border-radius: 2px;
      cursor: pointer;
    }
    .desc {
      margin-top: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #C3C3C3;
    }
    .select {
      input {
        border: none;
      }
    }
    .ant-picker {
      background-color: transparent;
      border-color: #ffffff;
      width: 100%;
      height: 54px;
      border-radius: 4px;
      input {
        color: #FFFFFF;
      }
      .ant-picker-suffix {
        margin-bottom: 0;
        .anticon.anticon-calendar {
          color: rgba(255, 255, 255, 0.5);
        }
      }
      .ant-picker-clear {
        height: 100%;
        margin-bottom: 0;
        background-color: #201e1e;
        .anticon.anticon-close-circle {
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
    &--error {
      input {
        border-color: colors.$primary;
      }
    }
  }
  &__error {
    color: red;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    position: absolute;
    bottom: -18px;
    width: 100%;
    margin-bottom: 0;
    text-align: center;
  }
  &__footer {
    margin-top: 60px;

    @media (max-width: 640px) {
      margin-top: 50px;
    }
    .btn {
      width: 240px;

      @media (max-width: 640px) {
        text-transform: uppercase;
      }
    }
  }
  &__passwords {
    margin-top: 40px;
    &--title {
      width: 100%;
      font-weight: 500;
      font-size: 41px;
      line-height: 50px;

      @media (max-width: 640px) {
        font-size: 26px;
        line-height: 32px;
        opacity: 0.9;
      }
    }
    .profile-edit-details__row {}
    .profile-edit-details__item {
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
