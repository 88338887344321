.profile-garage-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  flex-grow: 1;

  @media (max-width: 460px) {
    margin-top: 16px;
  }
}
