@use "../colors";

.events-same-slider {
  &__head {
    padding-bottom: 20px;
    border-bottom: 1px solid #888888;
    display: flex;
    align-items: flex-end;

    @media screen and (max-width: 460px) {
      width: 100%;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 41px;
    line-height: 50px;
    color: #FFFFFF;
    margin: 0;

    @media screen and (max-width: 460px) {
      font-size: 22px;
      line-height: 28px;
      white-space: normal;
    }
  }
  &__nav {
    margin-left: auto;
    padding-bottom: 10px;
  }
  &__list {
    margin-top: 30px;
    .swiper-slide {
      width: unset;
    }
  }
}

.events-same-slider-nav {
  display: flex;
  align-items: center;

  &__item {
    cursor: pointer;
    &:hover {
      svg {
        fill: colors.$primary;
      }
    }

    svg {
      fill: #ffffff;
      transition: 0.2s linear;
    }
    &--next {
      margin-left: 25px;
    }
    &.swiper-button-disabled {
      cursor: default;
      opacity: 0.3;

      &:hover {
        svg {
          fill: #ffffff;
        }
      }
    }
  }
}
