@use "../colors";

.radio {
  display: flex;
  justify-content: flex-start;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }
  &__indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 50%;
    border: 1px solid #ffffff;
  }
  &__label {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  &--primary {
    .radio__label {
      color: colors.$primary;
    }
  }
  &--active {
    .radio__indicator {
      &:after {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        background-color: colors.$primary;
        border-radius: 50%;
      }
    }
  }
}
