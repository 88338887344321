html {
  background-color: #201E1E;
}
body {
  position: relative;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  background-color: #201E1E;
  -webkit-font-smoothing: antialiased;
  &.scroll-lock {
    height: 100vh;
    overflow: hidden;
  }
}

.body-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  overflow: hidden;
  span {
    position: absolute;
    display: block;
    width: 350px;
    height: 576px;
    border-radius: 50%;
    background: #FF8746;
    opacity: 0.4;
    filter: blur(350px);
    transform: rotate(27.6deg);
    &:nth-child(1) {
      top: 1800px;
      left: 50%;
      transform: rotate(27.6deg) translateX(-50%);
    }
    &:nth-child(2) {
      top: 700px;
      left: 0;
      transform: rotate(27.6deg) translateX(-50%);
    }
    &:nth-child(3) {
      top: 900px;
      right: 0;
      transform: rotate(27.6deg) translateX(50%);
    }
    &:nth-child(4) {
      top: 3800px;
      left: 50%;
      transform: rotate(27.6deg) translateX(-50%);
    }
    &:nth-child(5) {
      top: 2700px;
      left: 0;
      transform: rotate(27.6deg) translateX(-50%);
    }
    &:nth-child(6) {
      top: 2900px;
      right: 0;
      transform: rotate(27.6deg) translateX(50%);
    }
    &:nth-child(7) {
      top: 5800px;
      left: 50%;
      transform: rotate(27.6deg) translateX(-50%);
    }
    &:nth-child(8) {
      top: 4700px;
      left: 0;
      transform: rotate(27.6deg) translateX(-50%);
    }
    &:nth-child(9) {
      top: 4900px;
      right: 0;
      transform: rotate(27.6deg) translateX(50%);
    }
  }
}

#root {
  position: relative;
  z-index: 2;
}

.container {
  width: 1400px;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;

  @media (max-width: 1440px) {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (max-width: 1000px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-width: 700px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 460px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.page-content {
  padding: 120px 0 80px;
  min-height: calc(100vh - 170px);

  @media screen and (max-width: 1440px) {
    min-height: calc(100vh - 150px);
  }

  @media screen and (max-width: 1000px) {
    min-height: calc(100vh - 100px);
  }

  @media screen and (max-width: 700px) {
    min-height: calc(100vh - 217px);
    padding: 120px 0 20px;
  }
  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
