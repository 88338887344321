@use "../colors";

.notifications-item {
  margin-top: 20px;
  position: relative;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
  border-radius: 10px;
  &__remove {
    position: absolute;
    top: 11px;
    right: 11px;
    z-index: 3;
  }
  &__wrap {
    padding: 20px;
    display: flex;
    align-items: flex-start;
    @media (max-width: 460px) {
      display: block;
      position: relative;
      padding: 10px 10px 25px;
    }
  }
  &__avatar {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    @media (max-width: 460px) {
      position: absolute;
      top: 15px;
      left: 10px;
      z-index: 1;
      width: 60px;
      height: 60px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &--empty {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 5px;
      background-color: #fff;
      img {
        opacity: 0.2;
        scale: 0.7;
      }
    }
  }
  &__main {
    margin-left: 15px;
    @media (max-width: 460px) {
      margin-left: 0;
    }
  }
  &__head {
    display: flex;
    align-items: baseline;
    @media (max-width: 460px) {
      height: 75px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-left: 75px;
    }
  }
  &__user {
    p {
      margin: 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }
  }
  &__date {
    font-size: 16px;
    line-height: 140%;
    margin-left: 15px;
    opacity: 0.7;
    @media (max-width: 460px) {
      margin-left: 0;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }
  &__text {
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    white-space: pre-wrap;
    a {
      color: colors.$primary;
      text-decoration: none;
    }
  }
  &__content {
    @media (max-width: 460px) {
      margin-top: 5px;
    }
  }
  &__actions {
    margin-top: 10px;
    display: flex;
    align-items: center;
    @media (max-width: 460px) {}
  }
  &__info {}
  &__accept {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 30px;
    background-color: colors.$primary;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    @media (max-width: 460px) {}
  }
  &__decline {
    margin-left: 20px;
    color: colors.$primary;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    cursor: pointer;
    @media (max-width: 460px) {}
  }
  &__readed {
    display: none;
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 3;
    color: colors.$primary;
    opacity: 0.5;
  }
  &--is-readed {
    border: 1px solid #303030;
    .notifications-item__remove {
      display: none;
    }
    .notifications-item__readed {
      display: block;
      pointer-events: none;
    }
  }
}
