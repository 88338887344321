/*html,*/
/*body {*/
/*  color: #fff;*/
/*  background-image: url("../src/components/Res/background.jpg");*/
/*  background-size: 100%;*/
/*}*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

section {
  color: #fff;
  padding-left: 80px;
  padding-right: 80px;
}

.DefLink {
  text-decoration: none;
  color: #d0d0d0;
}

.DefLink:hover {
  text-decoration: none;
  color: #ffffff;
}

.UnderlineOnHover:hover {
  text-decoration: underline;
}

.blured-block {
  backdrop-filter: blur(8px);
}

.siteTitle {
  font-family: "Montserrat", sans-serif;
  font-size: 66px;
  font-weight: 500;
  line-height: 68px;
  letter-spacing: 0;
  text-align: left;
}

.siteSubTitle {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
}

.EventCard {
  background: linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.03)) !important;
  border: none;
}

.CardBkg {
  background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) !important;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 1px;
}

.CardBkg > div {
  background-color: #201e1e;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  overflow: hidden;
}

.cell:hover {
  background-color: #f0f0f0;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  cursor: pointer;
}

.text-underline {
  text-decoration: underline;
}

.timelabel {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: #000000;
  cursor: pointer;
}

.timelabel:hover {
  background-color: blue;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  color: white;
}

.selectedtime {
  background-color: blue;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  color: white;
}

.carousel-indicators button {
  background-color: transparent !important;
  border-radius: 50%;
  border: 1px solid white !important;
  height: 10px !important;
  width: 10px !important;
}

.carousel-indicators button.active {
  background-color: white !important;
}

.hoverBtn:hover {
  background-color: rgba(255, 89, 0, 1) !important;
  border: 1px solid white !important;
}

.modalBackground {
  background-color: #201e1e !important;
  border: 1px solid #201e1e !important;
  color: white !important;
}

.modal-header {
  color: white !important;
}

.custom-modal,
.modal-content {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
}
