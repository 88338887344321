@use "../../colors";

.travel-page-gallery {
  margin: 80px 0 0;
  &__title {
    border-bottom: 1px solid #A5A5A5;
    padding: 20px 0;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  &__list {
    width: unset;
    @media (max-width: 460px) {
      width: 100%;
    }
    .swiper{
      padding-top: 50px;
      padding-bottom: 0;
      height: 566px;
      margin-left: 0;
      @media (max-width: 460px) {
        height: 270px;
      }
    }
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      color: #FFFFFF;
      opacity: 1;
      scale: 0.6;
    }

    .swiper-button-prev,
    .swiper-button-next {
      top: calc(100% - 20px);
      color: colors.$primary;
    }

    .swiper-button-prev{
      top:20px;
      left: 80vw;
      @media (max-width: 460px) {
        display: none;
      }
    }
    .swiper-button-next{
      top:20px;
      right: 0;
      @media (max-width: 460px) {
        display: none;
      }
    }
    .swiper-slide{
      display: flex;
      @media (max-width: 460px) {
        display: block;
        width: 100%;
        height: auto;
        position: relative;
      }
      img{
        @media (max-width: 460px) {
          width: 100%;
          height: auto;
        }
      }
      &.swiper{
        overflow: auto;
        &-slide{
          width: unset!important;
          height: 330px;
          @media (max-width: 460px) {
            width: 100%!important;
          }
          &-active{
            height: 500px;
          }
        }
      }
    }
  }
}
