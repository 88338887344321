.confirm-email-modal {
  &__title {
    font-size: 22px;
    line-height: 28px;
  }
  &__text {
    margin-top: 10px;
  }
  &__input {
    margin-top: 10px;
    input {
      display: block;
      width: 100%;
      height: 54px;
      padding: 0 15px;
      background-color: transparent;
      border: 1px solid #c3c3c3;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;

      &:focus {
        border-color: #ffffff;
        outline: none;
      }
      &::placeholder {
        color: #c3c3c3;
      }
    }
  }
  &__footer {
    margin-top: 20px;
    display: flex;
  }
  &__submit {

  }
  &__cancel {
    margin-left: 20px;
  }
}
