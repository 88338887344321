@use "../../colors";

.travel-page-top {
  display: flex;
  margin: 40px auto 0;

  @media (max-width: 1200px) {
    display: block;
  }
  @media (max-width: 460px) {
    flex-direction: column;
    margin: 20px 0 0;
    width: 100%;
  }
  &__main {
    width: 920px;

    @media (max-width: 1400px) {
      width: unset;
      flex-grow: 1;
    }
    @media (max-width: 460px) {
      width: 100%;
    }
  }
  &__map {
    flex-shrink: 0;
    width: 100%;
    height: 594px;
    background-color: rgba(#ffffff, 0.1);

    @media (max-width: 460px) {
      height: 420px;
    }
  }
  &__info {
    flex-shrink: 0;
    width: 440px;
    margin-left: 40px;

    @media (max-width: 1200px) {
      margin-left: 0;
    }

    @media (max-width: 460px) {
      width: 100%;
    }
  }
  &__points {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;

    @media (max-width: 460px) {
      margin-top: 20px;
      padding: 0 10px;
    }
  }
  &__point {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 132px;
    padding: 9px 0;
    border: 1px solid #ffffff;
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0 25px 25px 0;
    cursor: pointer;

    @media (max-width: 460px) {
      width: 28px;
      height: 28px;
      padding: 0;
      border-radius: 50%;
      margin: 0 14px 14px 0;
    }

    &--detail {
      margin-right: 4px;
      @media (max-width: 460px) {
        display: none;
      }
    }
    &--active {
      color: colors.$primary;
      border-color: colors.$primary;
      @media (max-width: 460px) {
        width: 132px;
        padding: 9px 0;
        border-radius: 10px;
      }
      .travel-page-top__point--detail {
        @media (max-width: 460px) {
          display: inline;

        }
      }
    }
  }
  &__desc {
    margin: 25px 0 0;
    font-size: 16px;
    line-height: 140%;

    @media (max-width: 460px) {
      margin-top: 20px;
    }
  }

  &__footer {
    margin-top: 60px;
    display: flex;
    justify-content: flex-start;

    @media (max-width: 1200px) {
      margin-top: 20px;
    }

    @media (max-width: 460px) {
      display: none;
    }
  }
  &__link {
    &.btn {
      display: flex;
      align-items: center;
      width: 100%;
      text-decoration: none;
      border-radius: 0;
      border: none;
      &:hover {
        color: #ffffff;
      }
      svg {
        margin-left: 10px;
      }
    }
  }
}

.travel-page-top-slider {
  @media (max-width: 460px) {
    margin-top: 65px;
  }
  &__item {
    width: 100%;
    height: 487px;

    @media (max-width: 460px) {
      height: 440px;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 4px;

      @media (max-width: 460px) {
        border-radius: 10px;
      }
    }
  }
}
