.dtp-page {
  &__wrap {

  }
  &__head {
    @media (max-width: 460px) {
      display: flex;
    }
    a {
      @media (max-width: 460px) {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #FFFFFF;
        text-decoration: none;
      }
      svg {
        @media (max-width: 460px) {
          margin-right: 8px;
        }
      }
    }
  }
  &__row {
    display: flex;

    @media (max-width: 1000px) {
      flex-direction: column;
    }

    @media (max-width: 460px) {
      margin-top: 30px;
    }
  }
}
