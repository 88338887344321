@use "../../colors";

.profile-create-event-form {
  margin-top: 20px;
  &__title {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  .checkbox,
  .radio {
    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
  }
  &__item {
    margin-top: 25px;
  }
  &__tab {
    display: flex;
    align-items: center;
    margin-top: 25px;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 35px;
      background-color: #c8c8c8;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #787878;
      cursor: pointer;
      &.active {
        background-color: colors.$primary;
        color: #ffffff;
      }
    }
    span:nth-child(1) {
      border-radius: 4px 0 0 4px;
    }
    span:nth-child(2) {
      border-radius: 0 4px 4px 0;
    }
  }
  &__submit {
    //@media (max-width: 460px) {
    //  display: none;
    //}

    //&--mobile {
    //  display: none;
    //
    //  @media (max-width: 460px) {
    //    display: flex;
    //  }
    //}
  }
  &__footer {
    margin-top: 30px;
    display: flex;
    align-items: center;
    
    @media (max-width: 1060px) {
      justify-content: center;
    }
  }
  &__errors {
    margin-left: 30px;
    p {
      margin: 0;
      color: colors.$primary;
    }
  }
}

.profile-create-event-form-images {
  display: flex;
  flex-wrap: wrap;
  &__item {
    position: relative;
    width: 192px;
    height: 112px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 20px;
    margin-bottom: 20px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    &:hover {
      .profile-create-event-form-images__hover {
        opacity: 1;
        pointer-events: auto;
      }
    }
    &--add {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      cursor: pointer;

      svg {
        display: block;
      }
    }
  }
  &__hover {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    pointer-events: none;
    transition: 0.2s linear;
  }
  &__remove {
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      display: block;
      margin-right: 3px;
      fill: #F0F0F0;
      transition: 0.2s linear;
    }
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #F0F0F0;
      transition: 0.2s linear;
    }
    &:hover {
      svg {
        fill: colors.$primary;
      }
      span {
        color: colors.$primary;
      }
    }
  }
}
