@use "../colors";

.team-table {
  margin-top: 20px;
  &__head {
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    opacity: 0.8;
    span {
      display: block;
      width: calc((100% - 40px) / 3);

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
  &__empty {
    margin-top: 20px;
    opacity: 0.5;
  }
  &__list {
    margin-top: 20px;
  }
  &__more {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}

.team-table-item {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  &__user {
    display: flex;
    align-items: center;
    width: calc((100% - 40px) / 3);
    margin-right: 20px;
  }
  &__avatar {
    flex-shrink: 0;
    a {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #fff;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &--empty {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 9px 5px 5px;

        img {
          object-fit: contain;
          height: unset;
          opacity: 0.2;
        }
      }
    }
  }
  &__name {
    margin-left: 10px;
    a {
      font-weight: 500;
      color: #ffffff;
      text-decoration: none;
      transition: 0.2s linear;
      &:hover {
        color: colors.$primary;
      }
    }
  }
  &__username {
    width: calc((100% - 40px) / 3);
    margin-right: 20px;
    a {
      font-weight: 500;
      color: #ffffff;
      text-decoration: none;
      transition: 0.2s linear;
      &:hover {
        color: colors.$primary;
      }
    }
  }
  &__actions {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc((100% - 40px) / 3);
  }
}

.team-table-item-mobile {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  &__head {
    display: flex;
    align-items: center;
  }
  &__avatar {
    flex-shrink: 0;
    a {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #fff;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &--empty {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 9px 5px 5px;

        img {
          object-fit: contain;
          height: unset;
          opacity: 0.2;
        }
      }
    }
  }
  &__details {
    margin-left: 10px;
  }
  &__name {
    display: flex;
    justify-content: flex-start;
    a {
      display: block;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #ffffff;
      text-decoration: none;
      transition: 0.2s linear;
      &:hover {
        color: #ffffff;
      }
    }
  }
  &__username {
    display: flex;
    justify-content: flex-start;
    a {
      display: block;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.6);
      text-decoration: none;
      transition: 0.2s linear;
      &:hover {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
  &__menu {
    margin-left: auto;
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
