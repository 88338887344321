.videos-page {
  padding: 100px 0;

  @media (max-width: 460px) {
    padding-bottom: 60px;
  }

  &__wrap {
    width: 1400px;
    margin: 0 auto;

    @media (max-width: 460px) {
      width: 100%;
      margin: 0;
      padding: 0 10px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 460px) {
      display: block;
    }
  }
  .profile-files-modal__wrap {
    width: 680px;
    height: calc(680px * 0.5625);

    @media (max-width: 760px) {
      width: 400px;
      height: calc(400px * 0.5625);
    }

    @media (max-width: 460px) {
      width: 80vw;
      height: calc(80vw * 0.5625);
    }
  }
  .profile-files-modal__view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;

    @media (max-width: 460px) {
      border-radius: 4px;
    }
  }
  iframe {
    display: block;
    width: 100%;
    height: 100%;
    //width: 680px;
    //height: calc(680px * 0.75);
    border-radius: 10px;
    border: none;

    //@media (max-width: 760px) {
    //  width: 400px;
    //  height: calc(400px * 0.75);
    //}

    @media (max-width: 460px) {
      border-radius: 4px;
      //width: 80vw;
      //height: calc(80vw * 0.75);
    }
  }
}

.videos-page-item {
  flex-shrink: 0;
  width: 440px;
  margin: 0 40px 40px 0;

  @media (max-width: 460px) {
    width: 100%;
    margin: 0 0 30px 0;
  }

  &:nth-child(3n) {
    margin-right: 0;
  }

  &__image {
    width: 100%;
    height: 270px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;

    @media (max-width: 460px) {
      border-radius: 4px;
      height: 200px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  p {
    margin: 19px 0 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;

    @media (max-width: 460px) {
      margin-top: 8px;
      font-size: 16px;
      line-height: 22px;
    }
  }
}
