@use "../colors";

.travel-card {
  width: 440px;

  @media screen and (max-width: 460px) {
    width: 100%;
  }
  &__wrap {
    height: 269px;
    position: relative;
    display: flex;
    background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
    box-shadow: 0px 4px 24px -1px rgb(0 0 0 / 20%);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 10px;

    @media screen and (max-width: 460px) {
    }

    &:before,
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: calc(100% - 26px);
      position: absolute;
      z-index: 5;
      top: 13px;
      background: linear-gradient(to bottom, #ffffff, #5a5959);
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
  &__bg {
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      z-index: 4;
      display: block;
      height: 50%;
      width: 100%;
      pointer-events: none;
    }
    &:before {
      top: 0;
      border-radius: 10px 10px 0 0;
      border: 1px solid #ffffff;
      border-bottom: none;
    }
    &:after {
      bottom: 0;
      border-radius: 0 0 10px 10px;
      border: 1px solid #5a5959;
      border-top: none;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;

    @media screen and (max-width: 460px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 15px;
      border-radius: 10px;
    }
  }

  &__map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
  }

  &__content {
    display: flex;
    flex-direction: column;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.65) 20%, rgba(255, 255, 255, 0.1) 40%, rgba(255, 255, 255, 0.1) 80%, rgba(0, 0, 0, 0.8) 100%);
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
    padding: 6px 8px 9px;
    position: relative;
    z-index: 3;
    border-radius: 10px;
    width: 100%;
    text-decoration: none;
    @media (max-width: 460px) {
      width: 100%;
    }
  }
  &__head {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: colors.$primary;
  }
  &__distance {
    margin-left: auto;
  }
  &__equipment {
    margin-left: 18px;
  }
  &__desc {
    margin-top: auto;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    @media screen and (max-width: 460px) {
      margin-bottom: 5px;
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__about {
    padding: 10px;
    display: flex;
    align-items: center;
  }
  &__author {
    flex-shrink: 0;
    position: relative;
    display: block;
    width: 49px;
    height: 49px;
    border-radius: 50%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      border: 1px solid #FFFFFF;
      object-position: center;
    }
    &--empty {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 5px;
      background-color: #fff;
      img {
        border-radius: 0;
        opacity: 0.2;
        scale: 0.7;
      }
    }
    &-tooltip {
      position: absolute;
      bottom: calc(100% + 5px);
      left: 0;
      z-index: 10;
      text-align: center;
      padding: 15px;
      border: 1px solid #ffffff;
      text-transform: none;
      pointer-events: none;
      color: #FFFFFF;
      opacity: 0;
      background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
      box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(20px);
      border-radius: 10px;
      transition: 0.2s ease-in;

      &:before {
        content: '';
        display: block;
        border: 4px solid #ffffff;
        border-bottom: none;
        border-left-color: transparent;
        border-right-color: transparent;
        position: absolute;
        left: 20px;
        bottom: -5px;
      }
    }
    &:hover {
      .travel-card__author-tooltip {
        opacity: 1;
      }
    }
  }
  &__title {
    margin-left: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  a {
    color: #FFFFFF;
    text-decoration: none;
    transition: 0.2s linear;

    &:hover {
      color: colors.$primary;
    }
  }
}

.travel-card__wrap-same {
  display: block;
  width: unset;
  img{
    position: relative;
    border-radius: 0;
    border: none;
  }
  .travel-card__content{
    background: #373737;
    border-radius: 0;
    border: none;
    height: 80%;
  }
  .travel-card__bg:before, .travel-card__bg:after {
    top: 0;
    border-radius: 10px 10px 0 0;
    border: none;
    border-bottom: none;
  }
  .travel-card__footer {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:before,
  &:after {
    content: '';
    display: none;
    width: 1px;
    height: calc(100% - 26px);
    position: absolute;
    z-index: 3;
    top: 13px;
    background: none;
  }

  @media screen and (max-width: 460px) {
    width: 100%;
  }
}

.travels-time{
  display: flex;
  margin: 0 40px;
  div{
    padding: 10px;

    margin-right: 20px;
    //background: #fff;
    border: 1px solid #ff5900;
    border-radius: 5px;
  }
  .active{
    background: #ff5900;
  }
}
