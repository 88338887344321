@use "../../colors";

.profile-settings-page {
  padding: 112px 0 70px;
  &__wrap {
    width: 1400px;
    padding: 0;

    @media (max-width: 460px) {
      width: 100%;
      padding: 0 15px;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 41px;
    line-height: 50px;

    @media (max-width: 460px) {
      font-size: 26px;
      line-height: 32px;
      opacity: 0.9;
    }
  }
  &__row {
    margin-top: 40px;
    display: flex;

    @media (max-width: 460px) {
      flex-direction: column;
      margin-top: 30px;
    }
  }
  &__col {
    &:not(:first-child) {
      margin-left: 100px;

      @media (max-width: 460px) {
        margin-left: 0;
        margin-top: 40px;
      }
    }
  }
  &__subtitle {
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;

    @media (max-width: 460px) {
      margin-bottom: 25px;
      font-size: 20px;
      line-height: 24px;
    }
  }
  &__group {
    .checkbox:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  &__link {
    margin-top: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: colors.$primary;
    cursor: pointer;
  }
  &__footer {
    margin-top: 60px;

    @media (max-width: 460px) {
      margin-top: 50px;
    }
    .btn {
      width: 240px;

      @media (max-width: 460px) {
        text-transform: uppercase;
      }
    }
  }
}
