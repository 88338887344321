.team-page {
  &-options{
    display: flex;
    justify-content: flex-end;
  }
  &__wrap {}
  &__head {
    display: flex;
    align-items: center;
    @media screen and (max-width: 700px) {
      flex-wrap: wrap;
    }
  }
  .main-events__search{
    @media screen and (max-width: 900px) {
      width: 270px;
    }
    @media screen and (max-width: 700px){
      margin: 10px 0 0;
      padding: 0;
    }
    @media screen and (max-width: 460px){
      width: unset;
    }
  }
  &-modal {
    &__title{
      font-size: 20px;
      line-height: 44px;
    }
    &__content{
      margin: 20px 0 0;
      img{
        display: block;
        width: 60px;
        height: 60px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
      }
    }
    &__details {
      margin-left: 10px;
    }
    &__user {
      display: flex;
      align-items: center;
    }
    &__actions {
      margin-top: 20px;
    }
    &__add{
      background: #ff5900;

      &:hover {
        color: #ffffff!important;
        border-color: transparent!important;
        outline: none!important;
      }
    }
    &__remove{
      border: none;
      color: #ff5900!important;
      font-weight: 400;
    }
  }
  &__tabs {
    display: flex;
    margin: 0;

    @media screen and (max-width: 700px) {
      width: 100%;
    }

    div {
      display: flex;
      justify-content: center;
      padding: 10px;
      width: 180px;
      cursor: pointer;
      border: 1px solid #ff5900;
      border-radius: 5px;
      @media screen and (max-width: 900px){
        width: 140px;
      }
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    .active {
      background: #ff5900;
    }
  }
  &__request {
    display: flex;
    position: relative;
    padding: 10px;
    width: 50px;
    @media screen and (max-width: 700px){
      margin: 10px 0 0;
    }

    p {
      margin: 0;
      padding: 2px 5px;
      font-size: 12px;
      line-height: 12px;
      position: absolute;
      background-color: #ff5900;
      bottom: 7px;
      right: 3px;
      border-radius: 12px;
    }
  }
  span[data-descr] {
    position: relative;
    text-decoration: none;
    cursor: help;
  }

  span[data-descr]:hover::after {
    content: attr(data-descr);
    position: absolute;
    right: 0;
    top: 37px;
    min-width: 200px;
    border: 1px #aaaaaa solid;
    border-radius: 10px;
    background-color: #ffffcc;
    padding: 5px;
    color: #000000;
    font-size: 12px;
    z-index: 1;
  }
}
.team-page-find-new {
  margin-top: 15px;

  &__text {}
  &__search {
    margin-top: 10px;
    position: relative;
    flex-shrink: 0;
    width: 362px;

    @media screen and (max-width: 460px) {
      width: auto;
      flex-grow: 1;
    }
    input {
      width: 100%;
      line-height: 36px;
      border: 1px solid #FFFFFF;
      background-color: transparent;
      color: #FFFFFF;
      border-radius: 2px;
      padding: 0 24px 0 10px;
      &:focus {
        outline: none;
        border-color: #ff5900;
      }
    }
    svg {
      position: absolute;
      top: 8px;
      right: 6px;
      z-index: 2;
      display: block;
      pointer-events: none;
    }
  }
}
