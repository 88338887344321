@use "../../colors";

.modal-users{
  height: 100%;
  padding-bottom: 40px;
  &__wrap {}
  &__title {
    font-size: 24px;
  }
  &__search {
    position: relative;
    flex-shrink: 0;
    width: 100%;
    margin-top: 20px;

    @media screen and (max-width: 460px) {
      width: auto;
      flex-grow: 1;
    }
    input {
      width: 100%;
      line-height: 36px;
      border: 1px solid #FFFFFF;
      background-color: transparent;
      color: #FFFFFF;
      border-radius: 2px;
      padding: 0 24px 0 10px;
      &:focus {
        outline: none;
        border-color: colors.$primary;
      }
    }
    svg {
      position: absolute;
      top: 8px;
      right: 6px;
      z-index: 2;
      display: block;
      pointer-events: none;
    }
  }
  &__list {
    margin-top: 10px;
    scrollbar-width: thin;
    scrollbar-color: #FF5900 #201e1e;
    overflow-y: auto;
    height: 400px;

    /* Работает в Chrome, Edge и Safari */
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #201e1e;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #FF5900;
      border-radius: 20px;
      border: 3px solid #FF5900;
    }
  }
  &__item {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .checkbox {
      margin-right: 15px;
      &__indicator {
        margin-right: 0;
      }
    }
  }
  &__avatar {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    &--empty {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 5px;
      background-color: #fff;
      img {
        opacity: 0.2;
        scale: 0.7;
      }
    }
  }
  &__user-info {
    margin-left: 15px;
  }
  &__name {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
  }
  &__callsign {
    margin-top: 3px;
    font-size: 16px;
    line-height: 20px;
    color: #888888;
  }
  &__footer {
    margin-top: 15px;
    display: flex;
    .btn {
      margin-right: 20px;
    }
  }

  //.ant-modal-content{
  //
  //  .ant-modal-body {
  //    height: 100%;
  //  }
  //}
}
