.travels-page {
  &__head {
    display: flex;
    align-items: flex-end;
    @media screen and (max-width: 1200px) {
      display: block;
    }
  }
  &__search {
    margin-left: auto;
    @media screen and (max-width: 1200px) {
      margin-left: 0;
      margin-top: 20px;
    }
  }
  .main-events__search {
    margin-left: 0;
    @media screen and (max-width: 740px) {
      width: 100%;
    }
  }

  .main-travels {
    &-content__wrap {
      width: 100%;
    }
    &__head{
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: end;
      &-item{
        display: grid;
        grid-template-columns: 2fr 2fr 3fr;
        gap: 40px;
        .select-label{
          margin-left: 10px;
        }
      }
    }

  }
  .main-travels-content__col-all {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
    min-height: 70vh;
    @media screen and (max-width: 460px) {
      display: block;
    }
  }
  .travel-card {
    margin-right: 20px;
    margin-bottom: 20px;

    @media screen and (max-width: 460px) {
      margin-right: 0;
      width: 100%;
    }

    //&:nth-child(3n) {
    //  margin-right: 0;
    //}
  }
}

.travels-page-selectors {
  display: flex;
  flex-shrink: 0;
  @media screen and (max-width: 740px) {
    flex-direction: column;
  }
  &__item {
    width: 200px;
    @media screen and (max-width: 740px) {
      width: 100%;
    }

    &:not(:last-child) {
      margin-right: 40px;
      @media screen and (max-width: 840px) {
        margin-right: 20px;
      }
      @media screen and (max-width: 740px) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}
