@use "../../colors";

.dtp-list-item {
  &:not(:last-child) {
    margin-bottom: 70px;

    @media (max-width: 460px) {
      margin-bottom: 40px;
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    @media (max-width: 700px) {
      display: block;
    }
  }
  &__left {
    flex-shrink: 0;
    width: 440px;

    @media (max-width: 1020px) {
      width: calc(50% - 20px);
    }

    @media (max-width: 840px) {
      width: calc(60% - 15px);
    }

    @media (max-width: 700px) {
      width: 100%;
    }
  }
  &__title {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    color: #FFFFFF;


    @media (max-width: 460px) {
      font-size: 20px;
      line-height: 24px;
      margin: 10px 0 0;
    }
  }
  &__author {
    margin-top: 5px;
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #ffffff;
    opacity: 0.5;

    @media (max-width: 700px) {
      margin-bottom: 15px;
    }
  }
  &__desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #FFFFFF;

  }
  &__footer {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 700px) {
      margin-top: 15px;
    }
  }
  &__link {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: colors.$primary;

    &:hover {
      color: colors.$primary;
    }
  }
  &__date {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    opacity: 0.7;
  }
  &__right {
    flex-shrink: 0;
    width: 440px;
    margin-left: 40px;

    @media (max-width: 1020px) {
      width: calc(50% - 20px);
    }

    @media (max-width: 840px) {
      margin-left: 30px;
      width: calc(40% - 15px);
    }

    @media (max-width: 700px) {
      width: 100%;
      margin-left: 0;
    }
  }
  &__image {
    @media (max-width: 700px) {
      margin-top: 15px;
    }
    img {
      display: block;
      width: 100%;
      height: 295px;
      object-fit: cover;

      @media (max-width: 700px) {
        height: unset;
      }
    }
  }
}
