@use "../../colors";

.main-in-your-city {
  margin-bottom: 100px;

  @media screen and (max-width: 460px) {
    margin-bottom: 50px;
  }

  &__title {
    margin-bottom: 15px;
    font-family: "Montserrat", sans-serif;
    font-size: 41px;
    line-height: 50px;

    @media screen and (max-width: 460px) {
      margin-bottom: 30px;
      font-size: 26px;
      line-height: 32px;
    }

    span {
      color: colors.$primary;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1100px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__map {
    padding: 5px;
    border: 1px solid #ffffff;
    border-radius: 10px;

    @media screen and (max-width: 560px) {
      width: 100%;
      margin-bottom: 30px;
    }

    img {
      display: block;

      @media screen and (max-width: 560px) {
        width: 100%;
      }
    }
  }
  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 500px;
    margin-left: 39px;
    padding: 20px 49px 38px 20px;
    background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 10px;

    @media screen and (max-width: 1100px) {
      height: auto;
      width: 100%;
      margin: 30px 0 40px;
      padding: 20px 28px 20px 20px;
    }
    &:before,
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: calc(100% - 26px);
      position: absolute;
      z-index: 3;
      top: 13px;
      background: linear-gradient(to bottom, #ffffff, #5a5959);
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
  &__bg {
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      z-index: 2;
      display: block;
      height: 50%;
      width: 100%;
      pointer-events: none;
    }
    &:before {
      top: 0;
      border-radius: 10px 10px 0 0;
      border: 1px solid #ffffff;
      border-bottom: none;
    }
    &:after {
      bottom: 0;
      border-radius: 0 0 10px 10px;
      border: 1px solid #5a5959;
      border-top: none;
    }
  }
  &__question {
    margin-bottom: 35px;
    font-weight: 500;
    font-size: 41px;
    line-height: 50px;

    @media screen and (max-width: 560px) {
      margin-bottom: 30px;
      font-size: 26px;
      line-height: 32px;
    }
  }
  &__sign-up {
    width: 347px;
    height: 69px;
    font-size: 32px;
    line-height: 39px;
    text-transform: none;
    border: none;
    border-radius: 59px;

    @media screen and (max-width: 1100px) {
      width: 245px;
      height: 52px;
      margin-bottom: 50px;
      font-size: 20px;
      line-height: 24px;
    }
  }
  &__text {
    width: 408px;
    margin-top: auto;
    margin-bottom: 34px;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;

    @media screen and (max-width: 560px) {
      width: 100%;
      margin-bottom: 30px;
      font-size: 20px;
      line-height: 24px;
    }
  }
  &__socials {
    display: flex;

    > a {
      margin-right: 50px;
      cursor: pointer;
    }
  }
}
