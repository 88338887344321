.event-details-author {
  width: 335px;
  flex-shrink: 0;
  @media (max-width: 1400px) {
    width: calc(50% - 10px);
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 740px) {
    width: calc(50% - 10px);
  }
  @media (max-width: 480px) {
    width: 100%;
  }
  &__head {
    color: #888888;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
  }
  &__wrap {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  &__avatar {
    &--empty {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 9px 5px 5px;

        img {
          object-fit: contain;
          height: unset;
          opacity: 0.2;
        }
      }
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #fff;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__user {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-left: 10px;
  }
}
