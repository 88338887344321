@use "../colors";

.checkbox {
  display: flex;
  justify-content: flex-start;
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }
  &__indicator {
    flex-shrink: 0;
    display: flex;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border: 1px solid colors.$primary;
    background-color: rgba(colors.$primary, 0);
    opacity: 0.8;
  }
  svg {
    display: none;
  }
  &__label {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  &--checked {
    .checkbox__indicator {
      background-color: rgba(colors.$primary, 1);
      opacity: 1;
    }
    svg {
      display: block;
    }
  }
}
