@use "../../colors";

.events-page {
  &__create {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: colors.$primary;
    border: none;
    padding: 0;
    margin-left: 20px;
    @media (max-width: 940px) {
      order: 2;
      margin-left: auto;
    }
  }
  .main-events {
    margin: 0;
  }
  .main-events__row {
    margin-top: 20px;
    @media screen and (max-width: 940px) {
      margin-top: 0;
    }
  }
  .main-events-content {
    margin-left: 0;
    @media screen and (max-width: 940px) {
      margin-top: 0;
    }
  }
  .main-events__city {
    width: 300px;
    margin: 0 40px 0 0;
    @media (max-width: 940px) {
      order: 1;
      margin: 0;
      width: calc(100% - 54px);
    }
  }
  .main-events-content__title {
    @media (max-width: 940px) {
      order: 3;
      width: 100%;
      margin-top: 5px;
    }
  }
  .main-events-content__wrap {
    width: 100%;
  }
  .main-events-content__head {
    margin-bottom: 30px;
    @media (max-width: 940px) {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }
    .btn {
      margin-left: auto;
      height: 46px;
    }
  }
  .main-events-content__list {
    display: block;
  }
  .main-events-content__col-all {
    display: flex;
    flex-wrap: wrap;
  }
  .main-events-content__list-all {
    min-height:70vh;
  }
  .main-events-content__col-all-past {
    display: flex;
    flex-wrap: wrap;
    .event-card__content{
      background: no-repeat url("../../../components/Res/pastMark.svg");
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 1;
    }
    .event-card__in-deal {
      display: none;
    }
    .event-card__member {
      display: none;
    }
  }
  .event-card {
    margin-right: 20px;
    margin-bottom: 20px;
    text-decoration: none;
    color: inherit;

    @media screen and (max-width: 460px) {
      margin-right: 0;
      width: 100%;
    }

    //&:nth-child(3n) {
    //  margin-right: 0;
    //}
  }
}

.events-time {
  display: flex;
  margin: 0;

  @media screen and (max-width: 940px) {
    width: 100%;
    margin-bottom: 20px;
  }
  a {
    display: flex;
    justify-content: center;
    padding: 10px;
    width: 130px;
    cursor: pointer;
    margin-right: 20px;
    //background: #fff;
    border: 1px solid #ff5900;
    border-radius: 5px;
    text-decoration: none;
    color: #ffffff;

    &:hover {
      color: #ffffff;
    }
  }
  .active {
    background: #ff5900;
  }
}
