@use '../../colors';

.profile-files-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(#1E1E1E, 0.8);
  display: none;
  justify-content: center;
  align-items: center;
  &__wrap {
    position: relative;
    //width: 1245px;
    //width: 849px;
    max-width: 80vw;
    height: 70vh;
  }
  &__close {
    position: absolute;
    top: -40px;
    right: -64px;
    cursor: pointer;
    @media (max-width: 800px) {
      top: -45px;
      right: -25px;
    }
  }
  &__content {
    display: flex;
    height: 100%;
  }
  &__view {
    //width: 849px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-color: #201E1E;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .swiper {
      height: 100%;
      .swiper-button-prev,
      .swiper-button-next {
        color: colors.$primary;
      }
    }
  }
  &__item {
    max-height: 100%;
  }
  &__comments {
    display: none;
    width: 396px;
    background-color: #201E1E;
    border: 1px solid #FFFFFF;
    border-radius: 0 10px 10px 0;
  }
  &--active {
    display: flex;
  }
}

.profile-files-upload-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  &__text {
    text-align: center;
    margin-top: 20px;
  }
}
.profile-files-upload-error-modal {
  padding: 20px;
  &__text {}
  &__error {
    margin-top: 20px;
    color: red;
  }
}
