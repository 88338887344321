.travel-page-head {
  display: flex;
  align-items: center;
  margin: 0 auto;

  @media (max-width: 460px) {
    flex-direction: column;
    width: 100%;
    padding: 0 10px;
  }


  &__title {
    flex-grow: 1;
    font-weight: 500;
    font-size: 66px;
    line-height: 102.5%;
    color: #FFFFFF;

    @media (max-width: 460px) {
      width: 100%;
      font-size: 20px;
    }
  }
  &__aside {
    flex-shrink: 0;
    width: 440px;
    margin-left: 40px;

    @media (max-width: 1200px) {
      margin-left: 0;
    }

    @media (max-width: 460px) {
      margin-top: 20px;
      margin-left: 0;
      width: 100%;
    }
  }
  &__fav {
    display: inline-flex;
    align-items: center;
    padding: 0 25px;
    height: 38px;
    font-size: 14px;
    line-height: 17px;
    color: #939393;
    border: 1px solid #939393;
    border-radius: 19px;
    cursor: pointer;

    svg {
      display: block;
      margin-right: 5px;
      fill: none;
      stroke: #939393;
    }
  }


}
