.event-details-info {
  margin: 20px 0 30px;
  &__top {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: #888888;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    img{
      margin-left: 15px;
    }
  }
  &__wrap {
    margin-top: 10px;
  }
  &__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #888888;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    padding: 15px 0;
    white-space: pre-wrap;

    @media (max-width: 480px) {
      display: block;
    }
  }
  &__label {
    display: flex;
    align-items: center;
    width: 50%;
    flex-shrink: 0;
    @media (max-width: 480px) {
      width: 100%;
    }

    img {
      margin-right: 10px;
    }
  }
  &__value {
    position: relative;
    width: 50%;
    flex-shrink: 0;

    @media (max-width: 480px) {
      margin-top: 10px;
      padding-left: 10px;
      width: 100%;
    }
    &:hover {
      .event-details-info__tooltip {
        opacity: 1;
      }
    }
    span {
      &:not(.event-details-info__tooltip) {

      }
    }
    .hidden {
      pointer-events: none;
    }
  }
  &__tooltip {
    position: absolute;
    right: 0;
    top: calc(100% + 34px);
    z-index: 10;
    transform: translateY(-50%);
    text-align: center;
    padding: 15px;
    border: 1px solid #ffffff;
    text-transform: none;
    pointer-events: none;
    color: #FFFFFF;
    opacity: 0;
    background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    transition: 0.2s ease-in;

    &:before {
      content: '';
      display: block;
      border: 4px solid #ffffff;
      border-top: none;
      border-left-color: transparent;
      border-right-color: transparent;
      position: absolute;
      top: -5px;
      left: calc(50% - 4px);
    }
  }
}
