@use "../../colors";

.garage-item {
  width: 478px;
  margin-right: 30px;
  margin-bottom: 30px;

  @media (max-width: 460px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
  }

  &:nth-child(2n) {
    margin-right: 0;
  }
  &__wrap {
    position: relative;
    background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    &:before,
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: calc(100% - 26px);
      position: absolute;
      z-index: 2;
      top: 13px;
      background: linear-gradient(to bottom, #ffffff, #5a5959);
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
  &__bg {
    pointer-events: none;
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      z-index: 1;
      display: block;
      height: 50%;
      width: 100%;
    }
    &:before {
      top: 0;
      border-radius: 10px 10px 0 0;
      border: 1px solid #ffffff;
      border-bottom: none;
    }
    &:after {
      bottom: 0;
      border-radius: 0 0 10px 10px;
      border: 1px solid #5a5959;
      border-top: none;
    }
  }
  &__content {
    position: relative;
  }
  &__images {
    width: 100%;
    height: 235px;
    border-radius: 9px;
    overflow: hidden;

    @media (max-width: 460px) {
      height: 166px;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &__info {
    padding: 17px 15px 23px;
  }
  &__name {
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;

    @media (max-width: 460px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  &__details {
    margin-top: 10px;
    p {
      margin: 0;
      font-size: 16px;
      line-height: 20px;

      @media (max-width: 460px) {
        font-size: 14px;
        line-height: 18px;
      }
      &:not(:first-child) {
        margin: 6px 0 0;
        @media (max-width: 460px) {
          margin-top: 4px;
        }
      }
    }
  }
  &__date {
    margin-top: 11px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #C3C3C3;

    @media (max-width: 460px) {
      margin-top: 5px;
    }
  }
  &__desc {
    margin-top: 22px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;

    @media (max-width: 460px) {
      margin-top: 15px;
      font-size: 14px;
      line-height: 140%;
    }
  }
  &__edit {
    color: colors.$primary;
    text-decoration: underline;
    cursor: pointer;
  }
  &__footer {
    padding: 0 15px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 460px) {
      margin-top: 20px;
    }
  }
  &__link {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: colors.$primary;
  }
  &__rent {
    .checkbox {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #C3C3C3;
    }
  }
  &__remove {
    color: colors.$primary;
    text-decoration: underline;
    cursor: pointer;
    //display: none;
    //position: absolute;
    //top: 10px;
    //right: 10px;
    //z-index: 5;
    //cursor: pointer;
    //opacity: 0.5;
    //transition: 0.2s linear;
    //
    //svg {
    //  fill: colors.$primary;
    //}
    //
    //&:hover {
    //  opacity: 1;
    //}
  }
  //&:hover {
  //  .garage-item__remove {
  //    display: block;
  //  }
  //}
}
