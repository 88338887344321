.event-page {
  &__head {

  }
  &__gallery {
    margin-top: 80px;
  }
  &__other {
    margin-top: 80px;
  }
  &__top {
    width: calc(50% - 20px);

    @media (max-width: 1200px) {
      display: flex;
      width: 100%;
    }
    @media (max-width: 740px) {
      display: block;
    }
  }
  &__image {
    position: relative;
    margin-top: 20px;
    height: 453px;

    @media (max-width: 1200px) {
      width: 50%;
      flex-shrink: 0;
    }
    @media (max-width: 740px) {
      height: 300px;
      width: 100%;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &--completed {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: no-repeat url("../../../components/Res/pastMark.svg"), rgba(0, 0, 0, 0.85);
      background-size: contain;
      background-position: center center;
    }
  }
}
