@use "../../colors";

.modal-event-to-travel {
  &__wrap {
  }
  &__title {
    font-size: 24px;
  }
  &__form {
    margin-top: 20px;
  }
  &__item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    span {
      display: block;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
    input {
      display: block;
      width: 100%;
      line-height: 46px;
      border: 1px solid #FFFFFF;
      background-color: transparent;
      color: #FFFFFF;
      border-radius: 4px;
      padding: 0 10px;
      transition: 0.2s linear;
      &:focus {
        outline: none;
        border-color: colors.$primary;
      }
    }
    textarea {
      display: block;
      width: 100%;
      height: 250px;
      resize: none;
      border: 1px solid #ffffff;
      background-color: transparent;
      color: #FFFFFF;
      border-radius: 4px;
      padding: 5px 10px;
      transition: 0.2s linear;
      &:focus {
        outline: none;
        border-color: colors.$primary;
      }
    }
  }
  &__errors {
    padding: 10px 0;
    color: red;

    p {
      margin: 0;
    }
  }
  &__footer {
    display: flex;
    //margin-top: 20px;

    .btn {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
