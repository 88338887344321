@use "../colors";

.breadcrumbs {
  display: flex;
  align-items: center;

  a {
    display: block;
    margin: 0 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #FFFFFF;
    text-decoration: none;
    transition: 0.2s linear;

    &:hover {
      color: colors.$primary;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      font-weight: 600;
      width: 250px;
      &:hover {
        color: #FFFFFF;
      }
    }
  }
}
