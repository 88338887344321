@use "../../colors";

.main-calendar {
  margin-top: 30px;
  &-reset{
    color: #ff5900;
    font-size: 14px;
  }
  &__legend {
    display: flex;
    margin: 30px 0 0;

    div {
      text-align: center;
      width: 54px;
      height: 34px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      @media (max-width: 460px) {
        width: calc((100vw - 72px) / 7);
      }
      &:not(:last-child) {
        margin-right: 10px;
        @media (max-width: 460px) {
          margin-right: 7px;
        }
      }
    }
  }
  &__body {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    &--loading {
      .main-calendar__item {
        opacity: 0.3;
      }
    }
  }
  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  //&__row {
  //  display: flex;
  //  margin-top: 13px;
  //  @media (max-width: 460px) {
  //    margin-top: 6px;
  //  }
  //}
  &__item {
    position: relative;
    display: block;
    width: 54px;
    height: 54px;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 10px;

    @media (max-width: 460px) {
      width: calc((100vw - 72px) / 7);
      height: calc((100vw - 72px) / 7);
      margin-right: 7px;
    }
    span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      color: #FFFFFF;
      border: 1px solid transparent;
      border-radius: 4px;
      //background-color: rgba(18, 17, 17, 0.4);
      @media (max-width: 460px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
      opacity: 0.6;
    }

    svg {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      z-index: 3;
      transform: translate(-50%, -50%);

      @media screen and (max-width: 1440px) {

      }
    }

    &:nth-child(7n) {
      margin-right: 0;
    }

    &--empty {
      pointer-events: none;
      opacity: 0;
    }
    &.active {
      span {
        border-color: rgba(colors.$primary, 1);
      }
    }
    &:hover {
      span {
        border-color: rgba(colors.$primary, 0.6);
      }
    }
  }
}

.main-calendar-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__item {
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__month {
    margin: 0 12px;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    color: #FFFFFF;
    @media (max-width: 460px) {
      font-size: 18px;
    }
  }
}
.main-calendar .main-calendar-reset{
  display: none;
}
.all-events-calendar .main-calendar-reset{
  display: block;
}

