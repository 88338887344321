.modal-leave-event-confirm {
  &__wrap {
    padding-top: 30px;
  }
  &__text {
    font-size: 18px;
  }
  &__footer {
    margin-top: 30px;
    display: flex;

    .btn {
      width: 100px;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
