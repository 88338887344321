@use "../../colors";

.main-app {
  margin-bottom: 102px;

  @media screen and (max-width: 460px) {
    margin-bottom: 40px;
  }

  &__wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 10px;

    @media screen and (max-width: 1160px) {
      flex-direction: column;
    }
    &:before,
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: calc(100% - 26px);
      position: absolute;
      z-index: 3;
      top: 13px;
      background: linear-gradient(to bottom, #ffffff, #5a5959);
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
  &__bg {
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      z-index: 2;
      display: block;
      height: 50%;
      width: 100%;
      pointer-events: none;
    }
    &:before {
      top: 0;
      border-radius: 10px 10px 0 0;
      border: 1px solid #ffffff;
      border-bottom: none;
    }
    &:after {
      bottom: 0;
      border-radius: 0 0 10px 10px;
      border: 1px solid #5a5959;
      border-top: none;
    }
  }
  &__content {
    padding: 45px;

    @media screen and (max-width: 580px) {
      padding: 15px 5px 15px 15px;
    }
  }
  &__title {
    margin-bottom: 19px;
    font-weight: 500;
    font-size: 41px;
    line-height: 50px;

    @media screen and (max-width: 580px) {
      margin-bottom: 15px;
      font-size: 26px;
      line-height: 32px;
    }

    span {
      color: colors.$primary;
    }
  }
  &__text {
    margin-bottom: 109px;
    line-height: 140%;
    width: 420px;

    @media screen and (max-width: 1000px) {
      width: 100%;
      margin-bottom: 30px;
      line-height: 140%;
    }
  }
  &__footer {
    display: flex;
    align-items: center;

    @media screen and (max-width: 560px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__link {
    position: relative;
    display: block;
    &:not(:last-child) {
      margin-right: 30px;

      @media screen and (max-width: 560px) {
        margin-right: 0;
      }
    }
    &:hover {
      span {
        opacity: 1;
      }
    }

    @media screen and (max-width: 560px) {
      display: block;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 38px;
      }
    }

    span {
      position: absolute;
      top: calc(100% + 5px);
      right: 50%;
      width: 100%;
      z-index: 2;
      transform: translateX(50%);
      text-align: center;
      padding: 15px;
      border: 1px solid #ffffff;
      text-transform: none;
      pointer-events: none;
      color: #ffffff;
      text-decoration: none;
      opacity: 0;
      background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
      box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(20px);
      border-radius: 10px;
      transition: 0.2s ease-in;

      &:before {
        content: '';
        display: block;
        border: 4px solid #ffffff;
        border-top: none;
        border-left-color: transparent;
        border-right-color: transparent;
        position: absolute;
        top: -5px;
        left: calc(50% - 4px);
      }
    }

    img {
      display: block;
      width: 208px;
      height: 62px;
    }
  }
  &__img {
    flex-grow: 1;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left center;
      border-radius: 0 10px 10px 0;

      @media screen and (max-width: 1160px) {
        border-radius: 0 0 10px 10px;
      }
    }
  }
}
