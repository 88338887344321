@use "../../colors";

.profile-create-equipment {
  &__wrap {
    padding: 67px 0;

    @media (max-width: 460px) {
      padding: 79px 0 70px;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 41px;
    line-height: 50px;

    @media (max-width: 460px) {
      font-size: 26px;
      line-height: 32px;
    }

    span {
      color: colors.$primary;
    }
  }
  &__form {
    //display: flex;
    //align-items: flex-start;
    margin-top: 35px;

    @media (max-width: 860px) {
      display: block;
    }
  }
  &__top {
    display: flex;
    align-items: flex-start;
    @media (max-width: 760px) {
      flex-direction: column;
    }
  }
  &__preview {
    flex-grow: 1;
    //width: 200px;


    @media (max-width: 760px) {
      order: 1;
    }
    &--title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
    &--list {
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__images {
    position: relative;
    width: 192px;
    height: 112px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 20px;
    margin-bottom: 20px;
    //margin: 0 10px 20px;

    //&:not(:nth-child(2n)) {
    //  margin-right: 20px;
    //}

    @media (max-width: 480px) {
      width: 120px;
      height: 80px;
      margin-right: 10px;
      margin-bottom: 10px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    &--add {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid colors.$primary;
      background-color: #fff;
      cursor: pointer;
      svg {
        display: block;
      }
    }
    &:hover {
      .profile-create-equipment__images-hover {
        opacity: 1;
        pointer-events: auto;
      }
    }
    &-hover {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      opacity: 0;
      pointer-events: none;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(#000, 0.7);
      transition: 0.2s linear;
    }
    &-remove {
      cursor: pointer;
      svg {
        fill: colors.$primary;
      }
    }
  }
  &__types {
    width: 500px;

    @media (max-width: 760px) {
      order: 2;
      width: 100%;
      margin-top: 20px;
    }
    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
    &-list {
      //display: flex;
      //flex-wrap: wrap;
      //align-items: center;
      margin-top: 15px;
    }
    .checkbox {
      margin-bottom: 20px;
      margin-right: 30px;
      //&:not(:nth-child(3n)) {
      //
      //}
    }
  }
  &__col {
    display: flex;
    align-items: flex-start;
    //width: 360px;
    //margin-left: 60px;
    margin-top: 20px;
    @media (max-width: 1200px) {
      display: block;
    }
    //@media (max-width: 460px) {
    //  width: 100%;
    //}
  }
  &__main {
    flex-grow: 1;

    @media (max-width: 1200px) {
      width: 100%;
    }
  }
  &__base {
    display: flex;
    align-items: center;
    margin-bottom: 35px;

    @media (max-width: 520px) {
      display: block;
      margin-bottom: 0;
    }
    .profile-create-equipment__item {
      width: calc(50% - 10px);
      @media (max-width: 520px) {
        width: 100%;
      }
      &:not(:last-child) {
        margin-right: 20px;
        @media (max-width: 520px) {
          margin-right: 0;
        }
      }
    }
  }
  &__values {
    display: flex;
    align-items: center;
    @media (max-width: 520px) {
      display: block;
    }
    .profile-create-equipment__item {
      width: calc((100% - 40px) / 3);
      @media (max-width: 520px) {
        width: 100%;
      }
      &:not(:last-child) {
        margin-right: 20px;
        @media (max-width: 520px) {
          margin-right: 0;
        }
      }
    }
  }
  &__desc {
    flex-shrink: 0;
    width: 400px;
    margin-left: 20px;

    @media (max-width: 1200px) {
      margin-top: 35px;
      margin-left: 0;
      width: 100%;
    }
  }
  &__item {
    position: relative;
    @media (max-width: 520px) {
      margin-bottom: 35px;
    }
    .profile-create-equipment__error {
      display: none;
    }
    input {
      display: block;
      width: 100%;
      height: 54px;
      padding: 0 15px;
      background-color: transparent;
      border: 1px solid #c3c3c3;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;

      &:focus {
        border-color: #ffffff;
        outline: none;
      }
      &::placeholder {
        color: #c3c3c3;
      }
    }
    textarea {
      resize: none;
      display: block;
      width: 100%;
      height: 167px;
      padding: 15px 10px;
      background-color: transparent;
      border: 1px solid #ffffff;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      &::placeholder {
        color: rgba(#ffffff, 0.5);
      }
      &:focus {
        outline: none;
      }
    }
    &--error {
      input {
        border-color: red;
      }
      textarea {
        border-color: red;
      }
      .profile-create-equipment__error {
        display: block;
      }
    }
  }
  &__error {
    position: absolute;
    bottom: -16px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 14px;
    line-height: 14px;
    color: red;
  }
  &__footer {
    margin-top: 60px;
    button {
      width: 240px;
    }
  }
}
