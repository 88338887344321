@use "../../colors";

.main-hero {
  position: relative;

  &__wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 6;
    display: flex;
  }

  &__content {
    position: relative;
    width: 50%;
    padding: 133px 24px 0 0;
    color: #ffffff;

    @media screen and (max-width: 1000px) {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
    }

    @media screen and (max-width: 460px) {
      width: 100%;
      padding: 96px 0 30px;
    }
  }

  &__title {
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 66px;
    line-height: 102.5%;

    @media screen and (max-width: 1440px) {
      font-size: 58px;
    }

    @media screen and (max-width: 1200px) {
      font-size: 48px;
    }

    @media screen and (max-width: 1000px) {
      font-size: 52px;
      margin-bottom: 0;
    }

    @media screen and (max-width: 460px) {
      font-weight: 700;
      font-size: 32px;
      line-height: 39px;
    }

    span {
      &:first-child {
        color: colors.$primary;
      }

      &:last-child {
        @media screen and (max-width: 460px) {
          display: none;
        }
      }
    }
  }

  &__desc {
    margin: 0;
    line-height: 140%;

    @media screen and (max-width: 1440px) {
      margin-top: 70px;
      font-size: 16px;
    }

    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  &__deco {
    display: block;
    position: absolute;
    bottom: 160px;
    left: 100%;
    transform: translateX(-50%);

    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  &__actions {
    margin: 150px 0 0 auto;

    @media screen and (max-width: 1000px) {
      display: none;
    }

    @media screen and (max-width: 460px) {
      display: none;
    }

    .action-btn {
      position: relative;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 110px;
      height: 43px;
      border: none;
      color: #ffffff;
      font-weight: 500;
      font-size: 20px;
      line-height: 29px;
      backdrop-filter: blur(10px);
      border-radius: 4px;
      cursor: not-allowed;
      pointer-events: auto;

      &:hover {
        .action-btn__tooltip {
          opacity: 1;
        }
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      &--red {
        background: rgba(255, 0, 41, 0.4);
      }

      &__tooltip {
        position: absolute;
        top: calc(100% + 10px);
        right: 20px;
        z-index: 2;
        text-align: center;
        padding: 15px;
        border: 1px solid #ffffff;
        pointer-events: none;
        font-size: 14px;
        line-height: 18px;
        opacity: 0;
        background: linear-gradient(145.9deg, rgba(255, 255, 255, 0.12) 17.78%, rgba(255, 255, 255, 0.03) 76.8%);
        box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(20px);
        border-radius: 10px;
        transition: 0.2s ease-in;

        &:before {
          content: '';
          display: block;
          border: 4px solid #ffffff;
          border-top: none;
          border-left-color: transparent;
          border-right-color: transparent;
          position: absolute;
          top: -5px;
          right: 30px;
        }
      }
    }
  }
}
