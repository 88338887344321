@use "../../colors";

.infrastructure-page {
  &__wrap {}
}

.infrastructure-page-form {
  &__row {
    display: flex;
    align-items: flex-end;
    margin-top: 30px;
  }
  &__item {
    margin-right: 20px;
    width: 30%;
    span {
      display: block;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }

    input {
      display: block;
      width: 100%;
      height: 50px;
      padding: 0 10px;
      background-color: transparent;
      border: 1px solid #ffffff;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      &::placeholder {
        color: rgba(#ffffff, 0.5);
      }
      &:focus {
        outline: none;
      }
    }
    textarea {
      display: block;
      width: 100%;
      height: 120px;
      resize: none;
      padding: 0 10px;
      background-color: transparent;
      border: 1px solid #ffffff;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      &::placeholder {
        color: rgba(#ffffff, 0.5);
      }
      &:focus {
        outline: none;
      }
    }
    .select__wrap {
      height: 50px;
      svg {
        top: 13px;
      }
    }
    .select__options {
      padding: 5px 0;
    }
  }
  .btn {
    &:disabled {
      &:hover {
        background-color: colors.$primary;
      }
    }
  }
}
.infrastructure-page-map {
  &__address {
    margin-top: 20px;
  }
}
